/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Modal,
} from "@mui/material";
import bgPattern from "../../assets/images/login/bg.svg";
import PasswordInput from "./PasswordInput";
import { useLocation, useNavigate } from "react-router-dom";
import pic from "../../assets/gesh.png";
import VideoPlayer from "../common/VideoPlayer";
import { savepassword } from "../../api/auth";
import { textFieldStyles } from "../../styles/inputStyles";
import TermsModal from "./TermsModal";
import useFetch from "../../utils/hooks/useFetch";
const validatePassword = (password) => {
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return passwordRegex.test(password);
};
const CreateOrganization = () => {
  const { data: onboardingData, error: onBoardingErr } =
    useFetch("/user/onboard-data");
  console.log(onboardingData);
  console.log(onBoardingErr);
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [helperText, setHelperText] = useState({
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    password: false,
    confirmPassword: false,
  });
  // console.log("password", password);

  useEffect(() => {
    setEmail(location.state);
  }, [location]);

  useEffect(() => {
    const passwordsMatch = password === confirmPassword;
    const isPasswordValid = validatePassword(password);

    setHelperText({
      password:
        password.trim() !== "" && !isPasswordValid
          ? "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long."
          : "",
      confirmPassword:
        confirmPassword.trim() !== "" && !passwordsMatch
          ? "Passwords don't match"
          : "",
    });

    setError({
      password: password.trim() !== "" && !isPasswordValid,
      confirmPassword: confirmPassword.trim() !== "" && !passwordsMatch,
    });

    setIsFormValid(
      agreed && isPasswordValid && passwordsMatch && password.trim() !== ""
    );
  }, [password, confirmPassword, agreed]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // navigate("/personalinfo");
    const formData = new FormData();
    formData.append("user_password", password);
    if (password) {
      let response = await savepassword(formData);
      console.log("pass", response);
      if (response.status === 201) {
        navigate("/personalinfo");
      } else {
        console.log("Could not verify password");
      }
    }
  };

  useEffect(() => {
    if (onboardingData && onboardingData.password) {
      navigate("/personalinfo");
    }
  }, [onboardingData]);

  return (
    <Grid container style={{ height: "100vh" }}>
      <Grid item xs={12} md={6} style={{ height: "100%", width: "100%" }}>
        <VideoPlayer />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <img
          src={bgPattern}
          alt=""
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            zIndex: "1",
            width: "400px",
            height: "400px",
          }}
        />
        <Box style={{ maxWidth: "400px", padding: "0 20px" }}>
          <img
            src={pic}
            alt="Logo"
            style={{ width: "100px", marginBottom: "20px" }}
          />
          <Typography
            variant="h1"
            gutterBottom
            style={{ fontFamily: "Inter", fontSize: "2rem", fontWeight: 500 }}
          >
            Register Account!
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginTop: "16px",
              marginBottom: "5rem",
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "1rem",
              color: "#8692A6",
            }}
          >
            Join us to access sustainability reports and track your progress
            towards a greener future.
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              size="small"
              label="Email Address"
              variant="outlined"
              disabled
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                marginBottom: 20,
                borderRadius: "10px",
              }}
              sx={textFieldStyles}
            />

            <Box
              style={{
                marginBottom: 20,
              }}
            >
              <PasswordInput
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={error.password}
                helperText={helperText.password}
                placeholder={"Create Password"}
              />
            </Box>
            <Box
              style={{
                marginBottom: 20,
              }}
            >
              <PasswordInput
                placeholder={"Re-enter Password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={error.confirmPassword}
                helperText={helperText.confirmPassword}
              />
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreed}
                  onChange={(e) => setAgreed(e.target.checked)}
                  sx={{
                    color: "primary",
                    "&.Mui-checked": {
                      color: "#43BAB9",
                    },
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontWeight: 400,
                    color: "#696F79",
                    fontSize: "15px",
                  }}
                >
                  I agree to{" "}
                  <span
                    onClick={() => {
                      setIsTermsOpen(true);
                    }}
                    style={{
                      background:
                        "linear-gradient(101.74deg, #369D9C 0%, #28814D 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                      textFillColor: "transparent",
                    }}
                  >
                    terms & conditions
                  </span>
                </Typography>
              }
              sx={{
                marginTop: "2rem",
                fontFamily: "Inter",
                fontWeight: 400,
                "& .MuiFormControlLabel-label": {
                  fontFamily: "Inter, Arial, sans-serif",
                },
              }}
            />
            <div style={{ width: "100%", position: "relative" }}>
              <button
                type="submit"
                disabled={!isFormValid}
                className="register_account_btn"
                style={{
                  width: "100%",
                  position: "relative",
                  zIndex: 2,
                }}
              >
                Register Account
              </button>
            </div>
          </form>
        </Box>
      </Grid>

      <TermsModal
        isTermsOpen={isTermsOpen}
        onClose={() => setIsTermsOpen(false)}
        onCancel={() => setAgreed(false)}
        onAgree={() => setAgreed(true)}
      />
    </Grid>
  );
};

export default CreateOrganization;
