// import React, { useState, useEffect } from "react";
// import cross from "../../assets/cross.svg";
// import checkMark from "../../assets/checkmark.svg";
// import {
//   fetchSubscriptionPlan,
//   activateSubscriptionPlan,
// } from "../../api/subscription";
// import { activePlan } from "../../api/subscription";
// import { loadStripe } from "@stripe/stripe-js";
// import {
//   Elements,
//   PaymentElement,
//   useStripe,
//   useElements,
// } from "@stripe/react-stripe-js";

// const PaymentForm = ({ clientSecret, onClose }) => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [processing, setProcessing] = useState(false);
//   const [error, setError] = useState(null);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!stripe || !elements) return;

//     setProcessing(true);
//     const { error } = await stripe.confirmPayment({
//       elements,
//       confirmParams: {
//         return_url: `${window.location.origin}/subscriptions`,
//       },
//     });

//     if (error) {
//       setError(error.message);
//       setProcessing(false);
//     }
//   };

//   return (
//     <div className="payment-overlay">
//       <div className="payment-modal">
//         <div className="payment-modal-header">
//           <h2>Complete Payment</h2>
//           <button onClick={onClose} className="close-button">
//             &times;
//           </button>
//         </div>
//         <form onSubmit={handleSubmit}>
//           <PaymentElement />
//           {error && <div className="error-message">{error}</div>}
//           <button
//             type="submit"
//             disabled={!stripe || processing}
//             style={{ marginTop: "20px" }}
//             className="your-subscription-get_started_filled"
//           >
//             {processing ? "Processing..." : "Pay Now"}
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// const YourSubscription = () => {
//   const [subscriptionPlans, setSubscriptionPlans] = useState([]);
//   const [activePlanData, setActivePlanData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [stripeLoading, setStripeLoading] = useState(false);
//   const [showPaymentForm, setShowPaymentForm] = useState(false);
//   const [stripeOptions, setStripeOptions] = useState(null);

//   useEffect(() => {
//     console.log("hey", subscriptionPlans);
//     fetchInitialData();
//   }, []);

//   const fetchInitialData = async () => {
//     try {
//       const [plansResponse, activePlanResponse] = await Promise.all([
//         fetchSubscriptionPlan(),
//         activePlan(),
//       ]);

//       if (plansResponse.data && activePlanResponse.data) {
//         setSubscriptionPlans(plansResponse.data.data);
//         setActivePlanData(activePlanResponse.data.data);
//       } else {
//         setError("Failed to load subscription data");
//       }
//       setLoading(false);
//     } catch (error) {
//       console.error("Error:", error);
//       setError("Failed to load subscription data");
//       setLoading(false);
//     }
//   };

//   const getFeaturesList = (features) => {
//     const featuresList = [];

//     featuresList.push({
//       included: features.numberOfReports > 0,
//       text: `Generate ${
//         features.numberOfReports === "Infinity"
//           ? "Unlimited"
//           : features.numberOfReports
//       } Reports`,
//     });

//     featuresList.push({
//       included: features.numberOfMembers > 0,
//       text: `Team Collaboration${
//         features.numberOfMembers > 0
//           ? ` (${features.numberOfMembers} Members)`
//           : ""
//       }`,
//     });

//     featuresList.push({
//       included: features.numberOfVettiedReports > 0,
//       text: `${
//         features.numberOfVettiedReports > 0
//           ? `${features.numberOfVettiedReports} Verified Reports Annually`
//           : "Vetting/Verification"
//       }`,
//     });

//     return featuresList;
//   };

//   const isActivePlan = (planId) => {
//     return activePlanData?.planId === planId;
//   };

//   const handleGetStarted = async (planId) => {
//     try {
//       setStripeLoading(true);
//       const response = await activateSubscriptionPlan(planId);

//       if (response.data && response.data.data) {
//         const { publishableKey, clientSecret } = response.data.data;

//         if (!publishableKey || typeof publishableKey !== "string") {
//           throw new Error("Invalid publishable key received from server");
//         }

//         setStripeOptions({
//           stripe: await loadStripe(publishableKey),
//           options: {
//             clientSecret,
//             appearance: {
//               theme: "stripe",
//             },
//           },
//         });
//         setShowPaymentForm(true);
//       } else {
//         throw new Error("Failed to activate plan - no data received");
//       }
//     } catch (error) {
//       console.error("Error during payment process:", error);
//       setError(error.message || "Failed to process payment");
//     } finally {
//       setStripeLoading(false);
//     }
//   };

//   if (loading) return <div>Loading...</div>;
//   // if (error) return <div>{error}</div>;

//   return (
//     <div className="your-subscriptions-container">
//       <div className="your-subscriptions-heading">
//         <h1>You've exhausted your credits!</h1>
//         <h2>Check out our amazing plans.</h2>
//       </div>

//       <div className="your-subscriptions-card_container">
//         {subscriptionPlans.map((plan) => (
//           <div key={plan.id} className="your-subscriptions-card">
//             <div className="your-subscriptions-card_heading">
//               <h2>{plan.name}</h2>
//               <h3>$ {plan.price}</h3>
//               <h4>
//                 {isActivePlan(plan.id) ? "Currently using" : "Upgrade Now!"}
//               </h4>
//             </div>

//             <div className="your-subscription-benefits_container">
//               <h3>Benefits</h3>
//               {getFeaturesList(plan.features).map((benefit, index) => (
//                 <div key={index} className="your-subscription_benefit">
//                   <img
//                     src={benefit.included ? checkMark : cross}
//                     alt={benefit.included ? "Check" : "Cross"}
//                     height={20}
//                     width={20}
//                   />
//                   <p>{benefit.text}</p>
//                 </div>
//               ))}
//             </div>

//             <div>
//               <button
//                 className={
//                   isActivePlan(plan.id)
//                     ? "your-subscription-get_started_outlined"
//                     : "your-subscription-get_started_filled"
//                 }
//                 onClick={() => handleGetStarted(plan.id)}
//                 disabled={isActivePlan(plan.id)}
//               >
//                 Get Started
//               </button>
//             </div>
//           </div>
//         ))}
//       </div>

//       {showPaymentForm && stripeOptions && (
//         <Elements stripe={stripeOptions.stripe} options={stripeOptions.options}>
//           <PaymentForm onClose={() => setShowPaymentForm(false)} />
//         </Elements>
//       )}

//       <style jsx>{`
//         .payment-overlay {
//           position: fixed;
//           top: 0;
//           left: 0;
//           right: 0;
//           bottom: 0;
//           background: rgba(0, 0, 0, 0.5);
//           display: flex;
//           justify-content: center;
//           align-items: center;
//           z-index: 1000;
//         }

//         .payment-modal {
//           background: white;
//           padding: 20px;
//           border-radius: 8px;
//           width: 100%;
//           max-width: 500px;
//           margin: 20px;
//         }

//         .payment-modal-header {
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           margin-bottom: 20px;
//         }

//         .close-button {
//           background: none;
//           border: none;
//           font-size: 24px;
//           cursor: pointer;
//         }

//         .error-message {
//           color: red;
//           margin: 10px 0;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default YourSubscription;

import React, { useState, useEffect } from "react";
import cross from "../../assets/cross.svg";
import checkMark from "../../assets/checkmark.svg";
import {
  fetchSubscriptionPlan,
  activateSubscriptionPlan,
  activePlan,
} from "../../api/subscription";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const PaymentForm = ({ clientSecret, onClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setProcessing(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/subscriptions`,
      },
    });

    if (error) {
      setError(error.message);
      setProcessing(false);
    }
  };

  return (
    <div className="payment-overlay">
      <div className="payment-modal">
        <div className="payment-modal-header">
          <h2>Complete Payment</h2>
          <button onClick={onClose} className="close-button">
            &times;
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <PaymentElement />
          {error && <div className="error-message">{error}</div>}
          <button
            type="submit"
            disabled={!stripe || processing}
            style={{ marginTop: "20px" }}
            className="your-subscription-get_started_filled"
          >
            {processing ? "Processing..." : "Pay Now"}
          </button>
        </form>
      </div>
    </div>
  );
};

const YourSubscription = () => {
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [activePlanData, setActivePlanData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [stripeOptions, setStripeOptions] = useState(null);

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      // First try to fetch subscription plans
      const plansResponse = await fetchSubscriptionPlan();

      if (plansResponse.data?.data) {
        setSubscriptionPlans(plansResponse.data.data);
      }

      // Try to fetch active plan separately
      try {
        const activePlanResponse = await activePlan();
        if (activePlanResponse.data?.data) {
          setActivePlanData(activePlanResponse.data.data);
        }
      } catch (activePlanError) {
        // If active plan fetch fails (quota not found), we still continue
        console.log("No active plan found:", activePlanError);
      }
    } catch (error) {
      console.error("Error fetching subscription data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getFeaturesList = (features) => {
    const featuresList = [];

    featuresList.push({
      included: features.numberOfReports > 0,
      text: `Generate ${
        features.numberOfReports === "Infinity"
          ? "Unlimited"
          : features.numberOfReports
      } Reports`,
    });

    featuresList.push({
      included: features.numberOfMembers > 0,
      text: `Team Collaboration${
        features.numberOfMembers > 0
          ? ` (${features.numberOfMembers} Members)`
          : ""
      }`,
    });

    featuresList.push({
      included: features.numberOfVettiedReports > 0,
      text: `${
        features.numberOfVettiedReports > 0
          ? `${features.numberOfVettiedReports} Verified Reports Annually`
          : "Vetting/Verification"
      }`,
    });

    return featuresList;
  };

  const isActivePlan = (planId) => {
    return activePlanData?.planId === planId;
  };

  const handleGetStarted = async (planId) => {
    try {
      setStripeLoading(true);
      const response = await activateSubscriptionPlan(planId);

      if (response.data?.data) {
        const { publishableKey, clientSecret } = response.data.data;

        if (!publishableKey || typeof publishableKey !== "string") {
          throw new Error("Invalid publishable key received from server");
        }

        setStripeOptions({
          stripe: await loadStripe(publishableKey),
          options: {
            clientSecret,
            appearance: {
              theme: "stripe",
            },
          },
        });
        setShowPaymentForm(true);
      } else {
        throw new Error("Failed to activate plan - no data received");
      }
    } catch (error) {
      console.error("Error during payment process:", error);
      alert("Failed to process payment. Please try again.");
    } finally {
      setStripeLoading(false);
    }
  };

  if (loading) return <div>Loading Plans..</div>;

  // Show subscription cards if we have plans data, regardless of active plan status
  if (!subscriptionPlans?.length)
    return <div>No subscription plans available</div>;

  return (
    <div className="your-subscriptions-container">
      <div className="your-subscriptions-heading">
        <h1>You've exhausted your credits!</h1>
        <h2>Check out our amazing plans.</h2>
      </div>

      <div className="your-subscriptions-card_container">
        {subscriptionPlans.map((plan) => (
          <div key={plan.id} className="your-subscriptions-card">
            <div className="your-subscriptions-card_heading">
              <h2>{plan.name}</h2>
              <h3>$ {plan.price}</h3>
              <h4>
                {isActivePlan(plan.id) ? "Currently using" : "Upgrade Now!"}
              </h4>
            </div>

            <div className="your-subscription-benefits_container">
              <h3>Benefits</h3>
              {getFeaturesList(plan.features).map((benefit, index) => (
                <div key={index} className="your-subscription_benefit">
                  <img
                    src={benefit.included ? checkMark : cross}
                    alt={benefit.included ? "Check" : "Cross"}
                    height={20}
                    width={20}
                  />
                  <p>{benefit.text}</p>
                </div>
              ))}
            </div>

            <div>
              <button
                className={
                  isActivePlan(plan.id)
                    ? "your-subscription-get_started_outlined"
                    : "your-subscription-get_started_filled"
                }
                onClick={() => handleGetStarted(plan.id)}
                disabled={isActivePlan(plan.id)}
              >
                Get Started
              </button>
            </div>
          </div>
        ))}
      </div>

      {showPaymentForm && stripeOptions && (
        <Elements stripe={stripeOptions.stripe} options={stripeOptions.options}>
          <PaymentForm onClose={() => setShowPaymentForm(false)} />
        </Elements>
      )}

      <style jsx>{`
        .payment-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .payment-modal {
          background: white;
          padding: 20px;
          border-radius: 8px;
          width: 100%;
          max-width: 500px;
          margin: 20px;
        }

        .payment-modal-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }

        .close-button {
          background: none;
          border: none;
          font-size: 24px;
          cursor: pointer;
        }

        .error-message {
          color: red;
          margin: 10px 0;
        }
      `}</style>
    </div>
  );
};

export default YourSubscription;
