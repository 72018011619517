function removeKeys(obj, keysToRemove) {
  if (obj === null || typeof obj !== "object") {
    return obj; // Return the value if it's not an object
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => removeKeys(item, keysToRemove));
  }

  const newObj = {};

  Object?.keys(obj)?.forEach((key) => {
    if (!keysToRemove?.includes(key)) {
      newObj[key] = removeKeys(obj[key], keysToRemove);
    }
  });

  return newObj;
}

// function filterEmptyObjects(obj) {
//   if (obj === null || typeof obj !== "object") {
//     return obj;
//   }

//   if (Array.isArray(obj)) {
//     return obj
//       .map((item) => filterEmptyObjects(item))
//       .filter((item) => item !== undefined && item !== "");
//   }

//   const newObj = {};

//   Object.keys(obj).forEach((key) => {
//     const value = obj[key];

//     if (typeof value === "object" && value !== null) {
//       // Special handling for objects with 'value' property
//       if ("value" in value) {
//         newObj[key] = value; // Keep the entire object as is, including null values
//       } else {
//         const filteredValue = filterEmptyObjects(value);
//         if (Object.keys(filteredValue).length > 0) {
//           newObj[key] = filteredValue;
//         }
//       }
//     } else if (value !== undefined && value !== "") {
//       newObj[key] = value;
//     }
//   });

//   return newObj;
// }

function filterEmptyObjects(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj
      .map((item) => filterEmptyObjects(item))
      .filter((item) => item !== undefined && item !== "");
  }

  const newObj = {};

  Object.keys(obj).forEach((key) => {
    // Skip if the key is "assign"
    if (key === "assign") {
      return;
    }

    const value = obj[key];

    if (typeof value === "object" && value !== null) {
      // Special handling for objects with 'value' property
      if ("value" in value) {
        newObj[key] = value; // Keep the entire object as is, including null values
      } else {
        const filteredValue = filterEmptyObjects(value);
        if (Object.keys(filteredValue).length > 0) {
          newObj[key] = filteredValue;
        }
      }
    } else if (value !== undefined && value !== "") {
      newObj[key] = value;
    }
  });

  return newObj;
}

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

const dataPointsToNotMap = [
  "score",
  "grade",
  "normalizedValue",
  "kpiValue",
  "approval",
  "assign",
  "approvalSchema",
  "unReadMessage",
];

const symbolsToNotMap = [
  "score",
  "grade",
  "_id",
  "createdAt",
  "updatedAt",
  "__v",
  "completedStatus",
];

const dataToNotSend = [
  "id",
  "score",
  "unit",
  "grade",
  "normalizedValue",
  "kpiValue",
  "__v",
  "updatedAt",
  "createdAt",
  "_id",
  "completedStatus",
  "approval",
  "unReadMessage",
];

const tooltipMapping = {
  B1: "GHG emissions intensity",
  B2: "Water Intensity",
  B3: "Hazardous waste Intensity",
  B4: "Non-hazardous waste Intensity",
  B5: "Sustainability budget as a percentage of Revenue",
  B6: "Percentage of electricity consumed that comes from clean sources plus purchase of REC certificates",
  E1: "Scope 1 and Scope 2 - CO2e emissions",
  E2: "Scope 3 - CO2e emissions",
  E3: "Amount of Water Consumed",
  E4: "Percentage of total waste disposed to landfill",
  E5: "NOx released into the environment",
  E6: "SOx released into the environment",
  E7: "Threshold H2S concentration for the flare technology in use",
  E8: "LA90 Noise level",
  E9: "Percentage of waste water treated before being disposed",
  S1: "Gender Ratio",
  S2: "Percentage of foreign employees",
  S3: "Percent of Direct to Indirect Employees",
  S4: "Average tenure of employees in the organisation",
  S5: "Hours of training provided to employees",
  S6: "Non-discriminatory policy in place",
  S7: "TRIR",
  S8: "Total number of employees hired through campus placements",
  G1: "Tax Relief/Incentives/Subsidies Received from the Government",
  G2: "Percentage of pension salary contributed by employer",
  G3: "Amount spent on local suppliers as a percentage of Gross Revenue",
  G4: "Amount spent on suppliers having registered business less than 5 years as a percentage of Gross Revenue",
  G5: "Amount spent on local communities as a percentage of Gross Revenue",
  G6: "Amount spent on innovative technologies as a percentage of Gross Revenue",
  G7: "Ethics policy in place",
  G8: "Percentage of resolved complaints",
  G9: "Number of Board members",
  G10: "Amount spent on CSR activities as a percentage of Gross Revenue",
};

export {
  removeKeys,
  filterEmptyObjects,
  debounce,
  dataPointsToNotMap,
  symbolsToNotMap,
  dataToNotSend,
  tooltipMapping,
};
