import React from "react";
import { BarStackHorizontal } from "@visx/shape";
import { Group } from "@visx/group";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { withTooltip, Tooltip, defaultStyles } from "@visx/tooltip";
import { LegendOrdinal } from "@visx/legend";
import { useSpring, animated } from "@react-spring/web"; // Import react-spring for animation
import localPoint from "@visx/event/lib/localPointGeneric";

// Tooltip styles
const green1 = "#66BB6A";
const green2 = "#81C784";
export const green3 = "#A5D6A7";
export const background = "#fff";

const defaultMargin = { top: 30, left: 60, right: 10, bottom: 40 };
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: "white",
  color: "black",
};

// accessors
const getQuarter = (d) => d?.label;

let tooltipTimeout;

// Create a separate animated bar component
const AnimatedBar = ({
  bar,
  events,
  showTooltip,
  hideTooltip,
  margin,
  showTooltipData,
}) => {
  const springProps = useSpring({
    width: bar.width,
    from: { width: 0 }, // Start the animation from width 0
  });

  return (
    <animated.rect
      key={`barstack-horizontal-${bar.index}`}
      x={bar.x}
      y={bar.y}
      height={bar.height}
      fill={bar.color}
      width={springProps.width} // Apply animated width
      onClick={() => {
        if (events) alert(`clicked: ${JSON.stringify(bar)}`);
      }}
      onMouseLeave={() => {
        tooltipTimeout = window.setTimeout(() => {
          hideTooltip();
        }, 300);
      }}
      onMouseMove={(event) => {
        if (tooltipTimeout) clearTimeout(tooltipTimeout);

        // Get the mouse position relative to the SVG using `localPoint`
        const eventSvgCoords = localPoint(event.target.ownerSVGElement, event);
        const left = eventSvgCoords?.x;
        const top = eventSvgCoords?.y;

        // Show the tooltip with the mouse position
        showTooltip({
          tooltipData: bar,
          tooltipLeft: left + 10, // Offset the tooltip a little from the mouse
          tooltipTop: top + 10,
        });
      }}
    />
  );
};

const HorizontalBarChart = ({
  width,
  height,
  data = [], // Data passed as a prop
  events = false,
  margin = defaultMargin,
  tooltipOpen,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  hideTooltip,
  showTooltip,
  colors = [],
  showLegend = false,
}) => {
  // const keys = ["Waste Water Treated", "Waste Water Generated"]; // Keys for the stack
  const keys =
    data.length > 0
      ? Object.keys(data[0]).filter((key) => key !== "label")
      : [];
  const colorShades = colors; // Shades of green for the bars

  // Scales
  const temperatureScale = scaleLinear({
    domain: [0, 100], // Assuming the maximum value of emissions is 100
    nice: true,
  });

  const dateScale = scaleBand({
    domain: data.map(getQuarter),
    padding: 0.2,
  });

  const colorScale = scaleOrdinal({
    domain: keys,
    range: colorShades,
  });

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  temperatureScale.rangeRound([0, xMax]);
  dateScale.rangeRound([yMax, 0]);

  const tickValues = Array.from({ length: 6 }, (_, i) => i * 20);

  return width < 10 ? null : (
    <div>
      <svg width={width} height={height}>
        <rect width={width} height={height} fill={background} rx={14} />
        <Group top={margin.top} left={margin.left}>
          <BarStackHorizontal
            data={data}
            keys={keys}
            height={yMax}
            y={getQuarter}
            xScale={temperatureScale}
            yScale={dateScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) => (
                  <AnimatedBar
                    key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                    bar={bar}
                    events={events}
                    showTooltip={showTooltip}
                    hideTooltip={hideTooltip}
                    margin={margin}
                  />
                ))
              )
            }
          </BarStackHorizontal>
          <AxisLeft
            hideAxisLine
            hideTicks
            scale={dateScale}
            tickFormat={(d) => d}
            stroke={green3}
            tickStroke={green3}
            tickLabelProps={{
              fontSize: 10,
              textAnchor: "end",
              dy: "0.33em",
            }}
          />
          <AxisBottom
            hideAxisLine
            hideTicks
            top={yMax}
            tickValues={tickValues}
            scale={temperatureScale}
            stroke={green3}
            tickStroke={green3}
            tickLabelProps={{
              fontSize: 9,
              textAnchor: "middle",
              fontWeight: 500,
              color: "#000",
            }}
            label="m³"
            labelProps={{
              dy: "0.4em",
              fontSize: 9,
              fontWeight: 500,
            }}
          />
        </Group>
      </svg>
      {showLegend && (
        <div
          style={{
            position: "absolute",
            top: margin.top / 2 - 10,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            fontSize: "14px",
          }}
        >
          <LegendOrdinal
            scale={colorScale}
            direction="row"
            labelMargin="0 15px 0 0"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              fontSize: 10,
              whiteSpace: "nowrap",
            }}
            shape={"circle"}
            shapeHeight={10}
            shapeWidth={10}
          />
        </div>
      )}
      {tooltipOpen && tooltipData && (
        <Tooltip top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div style={{ color: colorScale(tooltipData.key) }}>
            <strong
              style={{
                fontSize: 11,
                fontWeight: 400,
                color: "black",
                whiteSpace: "nowrap",
              }}
            >
              {tooltipData.key}
            </strong>
          </div>
          <div style={{ fontSize: 11, fontWeight: 400, color: "black" }}>
            {tooltipData.bar.data[tooltipData.key]}%
          </div>
          <div>
            <small style={{ fontSize: 11, fontWeight: 400, color: "black" }}>
              {tooltipData.bar.data.quarter}
            </small>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default withTooltip(HorizontalBarChart);
