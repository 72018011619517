import api from ".";

export const fetchSubscriptionPlan = async () => {
  try {
    const response = await api.get("/subscription-plan");
    console.log(response.data);
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Error fetching subscription plan:", error);
    return { data: null, error: "Failed to fetch subscription plan" };
  }
};

export const activePlan = async () => {
  try {
    const response = await api.get("/subscription-plan/active-plan");
    console.log(response.data);
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Error fetching subscription plan:", error);
    return { data: null, error: "Failed to fetch subscription plan" };
  }
};

export const activateSubscriptionPlan = async (planId) => {
  try {
    const response = await api.post(`/subscription-plan/activate/${planId}`);
    console.log(response.data);
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Error activating subscription plan:", error);
    return { data: null, error: "Failed to activate subscription plan" };
  }
};
