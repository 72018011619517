import api from ".";

export const reportApis = {
  initiateReport: async (data) => {
    try {
      const res = await api.post("/report/initiate", data);
      return res.data;
    } catch (error) {
      throw new Error(error.response);
    }
  },
};


export const deleteReport = async (id) => {
  try {
    const response = await api.delete(`report/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting report:", error);
    throw error;
  }
};
