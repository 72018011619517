import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import bannerImg from "../../assets/images/banner.png";
import UploadFilesModal from "../common/UploadModal";
import BookCall from "./BookCall";
import { useNavigate } from "react-router-dom";
import PageHeading from "../common/PageHeading/PageHeading";
import useFetch from "../../utils/hooks/useFetch";
import { organizationEndpoints } from "../../api/apiEndpoints";
import usePostApi from "../../utils/hooks/usePost";
import { reportEndpoints } from "../../api/endpoints/report.endpoints";
import useOrganizationContext from "../../context/OrgContext";
import { unitEndpoints } from "../../api/endpoints/unit.endpoints";

const styles = {
  fontSize: "13px",
  // borderColor: "#E0E0E0",
  "& .MuiOutlinedInput-root": {
    borderRadius: "5px",
    borderColor: "#E0E0E0",
    "& .MuiOutlinedInput-root.Mui-focused fieldset": {
      borderColor: "#C5D2CB", // Conditional border color
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "rgba(0, 0, 0, 0.60)",
    fontFamily: "Inter",
  },
  "& .MuiFormHelperText-root": {
    color: "red", // Custom helper text color
  },
  "& .MuiInputBase-input": {
    fontFamily: "Inter",
    borderColor: "#EEEEEE",
    maxHeight: "40px",
    fontSize: "14px",
    padding: "11.5px 12px", // Adjust the padding to reduce the size
  },
  "& .MuiInputLabel-root": {
    fontFamily: "Inter",
    fontSize: "13px",
    transform: "translate(12px, 12px) scale(1)", // Adjust the label position
    color: "#787878",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(15px, -7px) scale(0.8)", // Adjust the label position when it shrinks
  },
};

const selectStyles = {
  "& .MuiInputBase-input": {
    fontFamily: "Inter",
    borderColor: "#EEEEEE",
    maxHeight: "40px",
    padding: "10px 12px", // Adjust the padding to reduce the size
    fontSize: "14px",
  },

  "& .MuiInputLabel-root": {
    fontSize: "13px",
    color: "#787878",
  },

  "& .MuiInputLabel-root.Mui-focused": {
    color: "#787878", // Ensure the color remains #787878 when focused
  },

  "& .MuiInputLabel-root.MuiInputLabel-shrink": {
    color: "#787878", // Ensure the color is #787878 when shrunk (label moved up)
  },
};

function CreateReport({ closeView }) {
  // const { data, error, loading } = useFetch(
  //   organizationEndpoints.getOrganization
  // );
  const { data, error, loading } = useOrganizationContext();

  const {
    data: initiateReportRes,
    error: reportError,
    loading: reportLoading,
    post,
  } = usePostApi(reportEndpoints.initiateReport);

  const { data: unitsList } = useFetch(unitEndpoints.getUnits, {
    categories: ["weight", "volume"],
  });

  console.log(unitsList);

  const [showUpload, setShowUpload] = useState(false);
  const [showUploadDoc, setShowUploadDoc] = useState(false);
  const [bookCall, setBookCall] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    segment: "",
    year: "",
    companyName: "",
    license: "",
    total_site_area: "",
    units_produced_value: "",
    units_produced_unit: "",
    raw_material_consumption_value: "",
    raw_material_consumption_unit: "",
  });

  const navigate = useNavigate();

  const handleNavigateToAddDataPoints = () => {
    navigate(`/add-data-points/${initiateReportRes?.reportId}`, {
      state: {
        generationMethod: "manual",
      },
    });
  };

  console.log(formData);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    console.log(type);
    // If the input is of type 'number', convert the value to a number
    setFormData({
      ...formData,
      [name]: type === "number" ? +value : value,
    });
  };

  const areAllFieldsFilled = () => {
    // Exclude license, raw_material_consumption_value, and raw_material_consumption_unit from validation
    const fieldsToValidate = { ...formData };
    delete fieldsToValidate.license;

    const isRawMaterialsValid =
      (formData?.raw_material_consumption_value !== "" &&
        formData?.raw_material_consumption_unit?.trim() !== "") ||
      (formData?.raw_material_consumption_value === "" &&
        formData?.raw_material_consumption_unit?.trim() === "");

    delete fieldsToValidate?.raw_material_consumption_value;
    delete fieldsToValidate?.raw_material_consumption_unit;

    // Validate all remaining fields
    return Object.values(fieldsToValidate).every((field) => {
      if (typeof field === "string") {
        return field?.trim() !== "";
      }
      return field !== "" && isRawMaterialsValid;
    });
  };

  const handleCreateReport = async (e) => {
    e.preventDefault();

    // Create a copy of formData without the companyName field
    const { companyName, ...dataWithoutCompanyName } = formData;

    // Create the object to post, excluding companyName
    const dataToPost = { organization_id: data.id, ...dataWithoutCompanyName };

    Object.keys(dataToPost).forEach((key) => {
      if (
        dataToPost[key] === "" ||
        dataToPost[key] === null ||
        dataToPost[key] === undefined
      ) {
        delete dataToPost[key];
      }
    });

    console.log(dataToPost);
    await post(dataToPost)
      .then((res) => {
        if (res.status === 200) {
          setShowUpload(true);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (data) {
      setFormData((prevData) => ({
        ...prevData,
        companyName: data.name || "", // Set companyName if available
      }));
    }
  }, [data]);

  const currentYear = new Date().getFullYear();

  // Generate an array of the last 50 years
  const last50Years = Array.from(
    { length: 50 },
    (_, index) => currentYear - index
  );

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          padding: "0.8rem 24px",
          backgroundColor: "#fafafa",
          flexBasis: "60%",
        }}
      >
        <PageHeading onClick={() => closeView()} text={"Create Report"} />
        <Box
          sx={{
            padding: "16px",
            backgroundColor: "white",
            // border: "1px solid black",
            marginBottom: "15px",
            borderRadius: "1rem",
          }}
        >
          <Typography
            sx={{
              fontSize: { lg: "14px" },
              fontWeight: "500",
              fontFamily: "Inter",
              marginBottom: "1rem",
              // lineHeight: { lg: 3 },
            }}
          >
            Add name and select year
          </Typography>

          <form onSubmit={handleCreateReport}>
            <Grid container spacing={2.5} sx={{ marginBottom: "16px" }}>
              <Grid item xs={3.5}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    sx={styles}
                    name="name"
                    label="Report Name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={2}>
                <FormControl fullWidth size="small">
                  <InputLabel
                    sx={{
                      fontSize: "13px",
                      marginTop: "3px",
                      "&.Mui-focused": {
                        color: "#787878", // Color when the input is focused
                        fontSize: "13px",
                      },
                    }}
                    id="segment"
                  >
                    Report Segment
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="segment"
                    id="segment"
                    value={formData.segment}
                    label="Report Segment"
                    name="segment"
                    onChange={handleChange}
                    sx={selectStyles}
                  >
                    <MenuItem disabled value={""}>
                      Select Quarter
                    </MenuItem>
                    <MenuItem value={"Q1"}>Q1</MenuItem>
                    <MenuItem value={"Q2"}>Q2</MenuItem>
                    <MenuItem value={"Q3"}>Q3</MenuItem>
                    <MenuItem value={"Q4"}>Q4</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={1.3}>
                <FormControl fullWidth size="small">
                  <InputLabel
                    sx={{
                      fontSize: "13px",
                      marginTop: "3px",
                      "&.Mui-focused": {
                        color: "#787878", // Color when the input is focused
                        fontSize: "13px",
                      },
                    }}
                    id="year"
                  >
                    Year
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="year"
                    id="year"
                    value={formData.year}
                    label="Year"
                    name="year"
                    onChange={handleChange}
                    sx={selectStyles}
                  >
                    <MenuItem disabled value={""}>
                      Select year
                    </MenuItem>
                    {last50Years?.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={2023}>2023</MenuItem>
                    <MenuItem value={2024}>2024</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Typography
              sx={{
                fontSize: { lg: "14px" },
                fontWeight: "500",
                fontFamily: "Inter",
                marginBottom: "1rem",
                // lineHeight: { lg: 3 },
              }}
            >
              Please enter your company's details
            </Typography>

            <Grid container spacing={2.5} sx={{ mb: "12px" }}>
              <Grid item xs={2.5}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    sx={styles}
                    name="companyName"
                    label="Company Name"
                    value={formData.companyName}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2.5}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    sx={styles}
                    name="license"
                    label="License Number"
                    value={formData.license}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2.5}>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    fullWidth
                    sx={styles}
                    name="total_site_area"
                    label="Total site area in m2"
                    value={formData.total_site_area}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2.5} sx={{ mb: "12px" }}>
              <Grid item xs={2.5}>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    fullWidth
                    sx={styles}
                    name="units_produced_value"
                    label="Units Produced in Reporting Period"
                    value={formData.units_produced_value}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2.5}>
                <FormControl fullWidth size="small">
                  <InputLabel
                    sx={{
                      fontSize: "13px",
                      marginTop: "3px",
                      "&.Mui-focused": {
                        color: "#787878", // Color when the input is focused
                        fontSize: "13px",
                      },
                    }}
                    id="units_produced_unit"
                  >
                    Unit
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="units_produced_unit"
                    id="units_produced_unit"
                    value={formData.units_produced_unit}
                    label="Unit"
                    name="units_produced_unit"
                    onChange={handleChange}
                    sx={selectStyles}
                  >
                    <MenuItem disabled value={""}>
                      Select a unit
                    </MenuItem>
                    {unitsList?.data?.map((item) => {
                      return (
                        <MenuItem key={item?.id} value={item?.id}>
                          {item?.name}
                        </MenuItem>
                      );
                    })}
                    {/* <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2.5}>
              <Grid item xs={2.5}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    type="number"
                    sx={styles}
                    name="raw_material_consumption_value"
                    label="Raw material consumption"
                    value={formData.raw_material_consumption_value}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2.5}>
                <FormControl fullWidth size="small">
                  <InputLabel
                    sx={{
                      fontSize: "13px",
                      marginTop: "3px",
                      "&.Mui-focused": {
                        color: "#787878", // Color when the input is focused
                        fontSize: "13px",
                      },
                    }}
                    id="raw_material_consumption_unit"
                  >
                    Unit
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="raw_material_consumption_unit"
                    id="raw_material_consumption_unit"
                    value={formData.raw_material_consumption_unit}
                    label="Unit"
                    name="raw_material_consumption_unit"
                    onChange={handleChange}
                    sx={selectStyles}
                  >
                    <MenuItem disabled value={""}>
                      Select a unit
                    </MenuItem>
                    {unitsList?.data?.map((item) => {
                      return (
                        <MenuItem key={item?.id} value={item?.id}>
                          {item?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6.48}></Grid>
              <Grid item xs={1} sx={{ color: "#ff3a3a", fontSize: "12px" }}>
                {reportError ? reportError : ""}
              </Grid>
            </Grid>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                type="submit"
                style={{
                  width: "max-content",
                  padding: "6px 28px",
                  borderRadius: 6,
                  backgroundImage: areAllFieldsFilled()
                    ? "linear-gradient(102deg, #369D9C 0%, #28814D 100%)"
                    : "none",
                  backgroundColor: areAllFieldsFilled()
                    ? "transparent"
                    : "#E8E8E8",
                  fontWeight: 400,
                  fontSize: "13px",
                  fontFamily: "Inter",
                  color: areAllFieldsFilled() ? "#FFF" : "#B0B0B0",
                  border: "1px solid #DDD",
                  letterSpacing: "0.5px",
                  cursor: areAllFieldsFilled() ? "pointer" : "not-allowed",
                  textTransform: "none",
                  // float: "right",
                  marginTop: "1rem",
                }}
                onClick={() => areAllFieldsFilled()}
                disabled={!areAllFieldsFilled()} // Disable button if not all fields are filled
              >
                Next
              </Button>
            </div>
          </form>
        </Box>

        <Dialog
          open={showUpload}
          onClose={() => setShowUpload(false)}
          sx={{
            ".MuiBackdrop-root": {
              background: "rgba(0, 0, 0, 0.80)",
              // backdropFilter: "blur(6px)",
            },
            ".MuiPaper-root": {
              borderRadius: "10px",
            },
          }}
        >
          <DialogContent
            style={{ maxWidth: "650px", width: "100%", background: "#fdfdfd" }}
            sx={{
              padding: 0,
            }}
          >
            <Box
              style={{
                // textAlign: "center",
                // padding: "1rem",
                borderRadius: "10px",
                backgroundColor: "#FFF",
                cursor: "pointer",
                padding: "56px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontFamily: "Inter",
                  mb: "32px",
                  color: "#000000",
                }}
              >
                Choose how you want to generate the report.
              </Typography>
              <Box
                onClick={() => {
                  setShowUploadDoc(true);
                  setShowUpload(false);
                }}
                sx={{
                  borderRadius: "10.979px",
                  border: "1.372px solid #DBDBDB",
                  backgroundColor: "#FDFDFD",
                  height: "90px",
                  padding: "1rem",
                  display: "flex",
                  flexDirection: "row",
                  gap: "2rem",
                  // mt: ".5rem",
                  cursor: "pointer",
                  ":hover": {
                    backgroundColor: "#F6FFF7",
                  },
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="55"
                  height="55"
                  viewBox="0 0 80 81"
                  fill="none"
                >
                  <rect
                    y="0.938477"
                    width="80"
                    height="80"
                    rx="40"
                    fill="url(#paint0_linear_932_9567)"
                  />
                  <path
                    d="M52.2286 36.2336C52.2616 36.2411 52.2946 36.2486 52.3275 36.2561C52.2946 36.2636 52.2616 36.2711 52.2286 36.2786C48.7367 37.0783 44.4217 38.5143 41.833 41.103C39.2442 43.6917 37.8082 48.0067 37.0086 51.4986C37.0011 51.5317 36.9935 51.5646 36.9861 51.5975C36.9786 51.5646 36.9711 51.5317 36.9636 51.4986C36.1639 48.0067 34.7279 43.6917 32.1392 41.103C29.5505 38.5143 25.2354 37.0783 21.7436 36.2786C21.7105 36.2711 21.6776 36.2636 21.6447 36.2561C21.6776 36.2486 21.7105 36.2411 21.7436 36.2336C25.2354 35.4339 29.5505 33.998 32.1392 31.4092C34.7279 28.8205 36.1639 24.5055 36.9636 21.0136C36.9711 20.9806 36.9786 20.9476 36.9861 20.9147C36.9935 20.9476 37.0011 20.9806 37.0086 21.0136C37.8082 24.5055 39.2442 28.8205 41.833 31.4092C44.4217 33.998 48.7367 35.4339 52.2286 36.2336Z"
                    stroke="white"
                    stroke-width="2"
                  />
                  <path
                    d="M53.7878 48.5468C54.8612 49.6202 56.426 50.3295 57.9184 50.8019C56.426 51.2742 54.8612 51.9835 53.7878 53.0569C52.7145 54.1302 52.0051 55.6951 51.5328 57.1875C51.0605 55.6951 50.3511 54.1302 49.2778 53.0569C48.2045 51.9835 46.6396 51.2742 45.1472 50.8019C46.6396 50.3295 48.2045 49.6202 49.2778 48.5468C50.3511 47.4735 51.0605 45.9087 51.5328 44.4162C52.0051 45.9087 52.7145 47.4735 53.7878 48.5468Z"
                    stroke="white"
                    stroke-width="2"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_932_9567"
                      x1="0"
                      y1="0.938477"
                      x2="92.6224"
                      y2="20.1808"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#369D9C" />
                      <stop offset="1" stop-color="#28814D" />
                    </linearGradient>
                  </defs>
                </svg>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "500",
                      fontFamily: "Inter",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      fontSize: "18px",
                      color: "#000",
                    }}
                  >
                    Enter data using
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "500",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        background:
                          "linear-gradient(102deg, #369D9C 0%, #28814D 100%)",
                        backgroundClip: "text",
                        "-webkitBackgroundClip": "text",
                        "-webkitTextFillColor": "transparent",
                      }}
                    >
                      GE3S AI
                    </Typography>
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "13px",
                      fontWeight: "400",
                      fontFamily: "Inter",
                      color: "#9B9B9B",
                    }}
                  >
                    Let our AI assist you in generating report
                  </Typography>
                </Box>
              </Box>
              <Box
                onClick={handleNavigateToAddDataPoints}
                sx={{
                  borderRadius: "10.979px",
                  border: "1.372px solid #DBDBDB",
                  backgroundColor: "#FDFDFD",
                  height: "90px",
                  padding: "1rem",
                  display: "flex",
                  flexDirection: "row",
                  gap: "2rem",
                  mt: "21px",
                  cursor: "pointer",

                  ":hover": {
                    backgroundColor: "#F6FFF7",
                  },
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="55"
                  height="55"
                  viewBox="0 0 80 81"
                  fill="none"
                >
                  <rect
                    y="0.897461"
                    width="80"
                    height="80"
                    rx="40"
                    fill="url(#paint0_linear_932_9574)"
                  />
                  <path
                    d="M40 33.397C40 31.8057 40.6321 30.2795 41.7574 29.1543C42.8826 28.0291 44.4087 27.397 46 27.397H59.5V54.397H46C44.4087 54.397 42.8826 55.0291 41.7574 56.1543C40.6321 57.2796 40 58.8057 40 60.397"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.5 54.397H34C35.5913 54.397 37.1174 55.0291 38.2426 56.1543C39.3679 57.2796 40 58.8057 40 60.397V33.397C40 31.8057 39.3679 30.2795 38.2426 29.1543C37.1174 28.0291 35.5913 27.397 34 27.397H20.5V54.397Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M46.002 34.8975H53.502"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M46.002 40.8975H53.502"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M46.002 46.8979H53.502"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_932_9574"
                      x1="0"
                      y1="40.0641"
                      x2="80"
                      y2="40.0641"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#0DC06D" />
                      <stop offset="1" stop-color="#7AFCC0" />
                    </linearGradient>
                  </defs>
                </svg>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "500",
                      fontFamily: "Inter",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "18px",
                      gap: "5px",
                      color: "#000",
                    }}
                  >
                    Enter Data Manually
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "13px",
                      fontWeight: "400",
                      fontFamily: "Inter",
                      color: "#9B9B9B",
                    }}
                  >
                    Enter your data directly using our manual upload form
                  </Typography>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
        <UploadFilesModal
          open={showUploadDoc}
          reportId={initiateReportRes?.reportId}
          onClose={() => {
            setShowUpload(false);
            setShowUploadDoc(false);
          }}
        />
        <BookCall open={bookCall} onClose={() => setBookCall(false)} />
      </Box>
      <Box
        sx={{
          maxHeight: "350px",
          height: "100%",
          // padding: "0px 32px",
          backgroundColor: "white",
          // border: "1px solid black",
          marginBottom: "15px",
          display: "flex",
          flexGrow: 1,
          flexBasis: "40%",
        }}
      >
        <Box
          sx={{
            height: "100%",
            background: `url(${bannerImg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left",
            width: "40%",
            borderRadius: "8px 0 0 8px",
          }}
        ></Box>
        <Box
          sx={{
            paddingLeft: "2%",
            // flexBasis: 1,
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
            background:
              "linear-gradient(270.87deg, #FAFAFA -1.18%, #F4FFFF 52.58%)", // Apply the gradient here
            borderRadius: "0 8px 8px 0",
          }}
        >
          <div>
            <Typography sx={{ fontSize: "13px", fontFamily: "Inter" }}>
              Need Help with Your Sustainability Report?
            </Typography>
            <Typography
              sx={{ fontSize: "13px", fontWeight: "600", fontFamily: "Inter" }}
            >
              Book a call with a Senior Consultant.
            </Typography>
            <Button
              onClick={() => setBookCall(true)}
              variant="outlined"
              sx={{
                padding: "0.35rem 0.6rem 0.35rem 0.6rem",
                lineHeight: "1.5rem",
                fontSize: "0.7rem",
                fontWeight: 500,
                fontFamily: "Inter",
                borderRadius: "6px",
                border: "1px solid var(--G2, #369D9C)",
                textTransform: "none",
                marginTop: "1rem",
                background: "linear-gradient(102deg, #369D9C 0%, #28814D 100%)",
                color: "transparent",
                backgroundClip: "text",
                "-webkitBackgroundClip": "text",
                "-webkitTextFillColor": "transparent",
                "&:hover": {
                  border: "1px solid var(--G2, #369D9C)",
                  color: "#FFF", // Ensure text color is white
                  backgroundColor: "#32CD32", // Green background color
                  backgroundClip: "border-box", // Ensures the background color covers the whole button
                  "-webkitBackgroundClip": "border-box", // Webkit-specific background clip
                  "-webkitTextFillColor": "#FFF", // Ensure text color fills on hover
                },
              }}
            >
              Connect With an Expert
            </Button>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default CreateReport;
