// import React, { useEffect, useState } from "react";
// import messages from "../../../assets/messages.svg";
// import dummyUser from "../../../assets/dummyUser.png";
// import { useLocation } from "react-router-dom";

// const SocialKpi = ({ reportData, loading }) => {
//   console.log(reportData);

//   const keyMapping = {
//     totalMen: "Total Number of men in the workforce",
//     totalWomen: "Total Number of women in the workforce",
//     totalNonBinary:
//       "Total Number of people in the workforce who identify as non-binary",
//     foreignEmployees: "Number of foreign nationals in work-force",
//     directEmployees: "Number of Direct employees",
//     indirectEmployees:
//       "Number of Indirect employees (contractors, outsourced etc)",
//     averageTenure: "Average tenure of employees in the organisation",
//     trainingHours: "Hours of training provided to employees",
//     nonDiscriminatoryPolicy:
//       "Do you have a Non-discriminatory policy in place?",
//     trir: "TRIR (total recordable incident rate)",
//     campusHires: "Total number of employees hired through campus placements",
//   };

//   const unitMapping = {
//     "66d7562107b0aeb0cb66609a": "tCO2e",
//     "66d7562207b0aeb0cb66609d": "million USD",
//     "66d7562207b0aeb0cb6660a4": "m3",
//     "66d7562207b0aeb0cb6660a9": "tonnes",
//     "66d7562207b0aeb0cb6660ad": "MWh",
//     "66d7562207b0aeb0cb6660af": "MWh eq",
//     "66d7562207b0aeb0cb6660ab": "%",
//     "66d7562207b0aeb0cb6660b3": "dB",
//     "66d7562207b0aeb0cb6660b1": "ppm",
//     "66d7562307b0aeb0cb6660b7": "Number",
//     "66d7562307b0aeb0cb6660b9": "years",
//     "66d7562307b0aeb0cb6660bb": "hours/year/employee",
//     "66d7562307b0aeb0cb6660bd": "Yes/No",
//     "66d7562307b0aeb0cb6660bf": "Incidients per 20000 hours worked",
//   };

//   const headings = [
//     "Symbol",
//     "Data Point",
//     "Data Point Unit",
//     "Value",
//     "Comments",
//   ];

//   const location = useLocation();
//   // console.log(location.state.generationMethod);

//   // const isAiGenerated = location.state.generationMethod === "ai";
//   const isAiGenerated = false;

//   const [data, setData] = useState([]);
//   const [socialSpecificKpi, setSocialSpecificKpi] = useState(
//     JSON.parse(localStorage.getItem("social")) || {}
//   );

//   const processData = (baseData) => {
//     const { id, ...other } = baseData;
//     const processedData = [];

//     // Loop through each symbol (B1, B2, etc.)
//     Object.keys(other).forEach((symbol, symbolIndex) => {
//       const dataPoints = [];

//       // Loop through each data point inside the symbol (e.g., scope1Emissions, grossRevenue, etc.)
//       Object.keys(other[symbol]).forEach((key, keyIndex) => {
//         if (key === "score") return;
//         const unit = unitMapping[other[symbol][key].unit] || ""; // Get the unit or fallback to empty
//         const point = keyMapping[key] || key; // Map key to human-readable name or fallback to the key itself

//         dataPoints.push({
//           id: `${symbol}-${keyIndex}`,
//           point,
//           ptKey: key,
//           unit,
//           value: other[symbol][key]?.value || "", // Include the value if available from API
//         });
//       });

//       processedData.push({
//         id: symbolIndex,
//         symbol,
//         dataPoints,
//         messages: "read", // Assuming default messages as 'read'
//       });
//     });

//     return processedData;
//   };

//   const updateData = (apiData) => {
//     const savedData = JSON.parse(localStorage.getItem("social")) || {};

//     for (const key in apiData) {
//       if (typeof apiData[key] === "object") {
//         for (const subKey in apiData[key]) {
//           if (apiData[key][subKey].hasOwnProperty("unit")) {
//             // Preserve the unit and only update the value
//             apiData[key][subKey] = {
//               ...apiData[key][subKey], // Keep the unit
//               value: savedData[key]?.[subKey]?.value || "", // Update the value
//             };
//           }
//         }
//       }
//     }

//     return apiData;
//   };

//   useEffect(() => {
//     if (!loading && reportData) {
//       const apiData = reportData;
//       const { id, score, ...otherApiData } = apiData; // Removing ID if necessary
//       console.log(otherApiData);

//       // Update the local state and merge with localStorage data
//       const updatedData = updateData(otherApiData);
//       setSocialSpecificKpi(updatedData);

//       // Process the data for display purposes (if necessary)
//       const processedData = processData(updatedData);
//       setData(processedData);
//     }
//   }, [reportData, loading]);

//   const handleInputChange = (symbol, dataPointKey, value, isButton = false) => {
//     setSocialSpecificKpi((prevState) => {
//       const updatedState = {
//         ...prevState,
//         [symbol]: {
//           ...prevState[symbol],
//           [dataPointKey]: {
//             ...prevState[symbol][dataPointKey],
//             value: isButton
//               ? value // Handle boolean for Yes/No button
//               : value === "" // For input field, handle empty value
//               ? ""
//               : !isNaN(value) // Ensure numeric values for input fields
//               ? +value
//               : value, // Set string value for text fields
//           },
//         },
//       };

//       // Update localStorage
//       localStorage.setItem("social", JSON.stringify(updatedState));
//       return updatedState;
//     });

//     // Also update the data to trigger re-render
//     setData((prevData) =>
//       prevData.map((item) =>
//         item.symbol === symbol
//           ? {
//               ...item,
//               dataPoints: item.dataPoints.map((pt) =>
//                 pt.ptKey === dataPointKey
//                   ? { ...pt, value: isButton ? value : value }
//                   : pt
//               ),
//             }
//           : item
//       )
//     );
//   };

//   // Ensure localStorage is updated whenever basicSectorSpecificKPI state changes
//   useEffect(() => {
//     localStorage.setItem("social", JSON.stringify(socialSpecificKpi));
//   }, [socialSpecificKpi]);

//   return (
//     <div
//       style={{
//         width: "100%",
//         overflow: "auto",
//       }}
//     >
//       <div className="new-kpi-table-container">
//         <div className="new-kpi-table">
//           <div className="new-kpi-table-header">
//             <div className="new-kpi-table-row new-kpi-table-head-row">
//               <div className="new-kpi-table-cell header-cell centered">
//                 <div className="new-kpi-table-header-content">Symbol</div>
//               </div>
//               <div className="new-kpi-table-cell header-cell">
//                 <div className="new-kpi-table-header-content">Data Point</div>
//               </div>
//               <div className="new-kpi-table-cell header-cell">
//                 <div className="new-kpi-table-header-content">
//                   Data Point Unit
//                 </div>
//               </div>
//               <div className="new-kpi-table-cell header-cell centered">
//                 <div className="new-kpi-table-header-content">Value</div>
//               </div>
//               <div className="new-kpi-table-cell header-cell centered">
//                 <div className="new-kpi-table-header-content">Comments</div>
//               </div>
//             </div>
//           </div>

//           <div className="new-kpi-table-body">
//             {data.map((item) => {
//               const missingValues = item.dataPoints.some(
//                 (pt) => pt.value === "" || !pt.value
//               );

//               return (
//                 <div
//                   key={item.id}
//                   className="new-kpi-table-row new-kpi-table-body-row"
//                 >
//                   <div
//                     style={{
//                       backgroundColor:
//                         isAiGenerated && missingValues ? "#ffeded" : "white",
//                     }}
//                     className="new-kpi-table-cell centered"
//                   >
//                     <div className="new-kpi-table-cell-content">
//                       {item.symbol}
//                     </div>
//                   </div>
//                   <div className="new-kpi-table-cell">
//                     {item.dataPoints.map((pt) => (
//                       <div
//                         key={pt.point}
//                         className="new-kpi-table-cell-content data"
//                         style={{
//                           backgroundColor:
//                             isAiGenerated && pt.value !== ""
//                               ? "#F2F2F2"
//                               : "white",
//                         }}
//                       >
//                         {pt.point}
//                       </div>
//                     ))}
//                   </div>
//                   <div className="new-kpi-table-cell">
//                     {item.dataPoints.map((pt) => (
//                       <div
//                         key={`${pt.point}-${pt.unit}`}
//                         className="new-kpi-table-cell-content data"
//                         style={{
//                           backgroundColor:
//                             isAiGenerated && pt.value !== ""
//                               ? "#F2F2F2"
//                               : "white",
//                         }}
//                       >
//                         {pt.unit}
//                       </div>
//                     ))}
//                   </div>
//                   <div className="new-kpi-table-cell centered">
//                     {item.dataPoints.map((pt) => {
//                       return pt.unit === "Yes/No" ? (
//                         <div
//                           key={`${pt.id}-${pt.point}-${pt.value}`}
//                           className="new-kpi-table-cell-content input-wrapper"
//                         >
//                           <button
//                             className={`yes-no-btn yes-btn ${
//                               pt.value === true ? "yes-btn-selected" : ""
//                             }`}
//                             onClick={() =>
//                               handleInputChange(
//                                 item.symbol,
//                                 pt.ptKey,
//                                 true,
//                                 true
//                               )
//                             }
//                           >
//                             Yes
//                           </button>
//                           <button
//                             className={`yes-no-btn no-btn ${
//                               pt.value === false ? "no-btn-selected" : ""
//                             }`}
//                             onClick={() =>
//                               handleInputChange(
//                                 item.symbol,
//                                 pt.ptKey,
//                                 false,
//                                 true
//                               )
//                             }
//                           >
//                             No
//                           </button>
//                         </div>
//                       ) : (
//                         <div
//                           key={`${pt.id}-${pt.point}-${pt.value}`}
//                           className="new-kpi-table-cell-content input-wrapper"
//                           style={{
//                             backgroundColor:
//                               isAiGenerated && !pt.value ? "#FFEDED" : "",
//                           }}
//                         >
//                           <input
//                             value={pt.value}
//                             type="number"
//                             onKeyPress={(e) => {
//                               if (e.key === "e" || e.key === "-") {
//                                 e.preventDefault();
//                               }
//                             }}
//                             onKeyDown={(e) => {
//                               if (e.key === "e" || e.key === "-") {
//                                 e.preventDefault();
//                               }
//                             }}
//                             className="input-field"
//                             placeholder="Enter value"
//                             onChange={(e) =>
//                               handleInputChange(
//                                 item.symbol,
//                                 pt.ptKey,
//                                 e.target.value
//                               )
//                             }
//                           />
//                         </div>
//                       );
//                     })}
//                   </div>

//                   <div className="new-kpi-table-cell centered">
//                     <div className="new-kpi-table-cell-content">
//                       {item.messages === "unread" ? (
//                         <div className="messages-wrapper">
//                           <div className="profile-images-container">
//                             {[1, 2, 3, 4, 5].slice(0, 3).map((img) => (
//                               <img
//                                 key={img}
//                                 src={dummyUser}
//                                 alt="Messaged"
//                                 height={28}
//                                 width={28}
//                               />
//                             ))}
//                             {[1, 2, 3, 4, 5].length > 4 && (
//                               <div className="extra-images">
//                                 +{[1, 2, 3, 4, 5].length - 3}
//                               </div>
//                             )}
//                           </div>
//                           <div className="notifications-div">
//                             <img
//                               src={messages}
//                               alt="Messaged"
//                               height={24}
//                               width={24}
//                             />
//                             <span className="unread">2 Unread</span>
//                           </div>
//                         </div>
//                       ) : item.messages === "read" ? (
//                         <div className="messages-wrapper">
//                           <div className="profile-images-container">
//                             {[1, 2, 3, 4].map((img) => (
//                               <img
//                                 key={img}
//                                 src={dummyUser}
//                                 alt="Messaged"
//                                 height={28}
//                                 width={28}
//                               />
//                             ))}
//                           </div>
//                           <div className="notifications-div">
//                             <img
//                               src={messages}
//                               alt="Messaged"
//                               height={24}
//                               width={24}
//                             />
//                             <span className="read">0 Unread</span>
//                           </div>
//                         </div>
//                       ) : (
//                         item.messages === "not assigned" && (
//                           <div
//                             style={{
//                               display: "flex",
//                               alignItems: "center",
//                               justifyContent: "center",
//                             }}
//                           >
//                             <button className="assign-btn">Assign</button>
//                           </div>
//                         )
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SocialKpi;

import React, { useCallback, useEffect, useState } from "react";
import dummyUser from "../../../assets/dummyUser.png";
import messages from "../../../assets/messages.svg";
import usePutApi from "../../../utils/hooks/usePutApi";
import { reportEndpoints } from "../../../api/endpoints/report.endpoints";
import { useLocation, useParams } from "react-router-dom";
import {
  dataPointsToNotMap,
  dataToNotSend,
  debounce,
  filterEmptyObjects,
  removeKeys,
  symbolsToNotMap,
  tooltipMapping,
} from "../../../utils/data-points/cleanObj";
import useFetch from "../../../utils/hooks/useFetch";
import { unitEndpoints } from "../../../api/endpoints/unit.endpoints";
import Loader from "../../Loader";
import { Tooltip } from "@mui/material";
import AssignButton from "../reports/rejected/AssignButton";
import AssignModal from "./assignments/AssignModal";
import { useTaggingStore } from "./assignments/chatStore";
import constant from "../../../constant";
import emptyuser from "../../../assets/emptyuser.avif";

const SocialKpi = () => {
  const location = useLocation();
  const isAiGenerated = location?.state?.generationMethod === "ai" || "";
  const params = useParams();
  const { put: saveProgress } = usePutApi(
    reportEndpoints.saveProgress(params.id)
  );
  const { data, loading } = useFetch(reportEndpoints.getReport(params.id));
  const reportData = data?.report?.socialKPI;
  const {
    data: unitsData,
    loading: unitsLoading,
    error: unitsError,
  } = useFetch(unitEndpoints.getUnits);
  const { allUsers } = useTaggingStore();

  const localStorageKey = `socialKpiData`;

  const headings = [
    "Symbol",
    "Data Point",
    "Data Point Unit",
    "Value",
    // "Comments",
  ];

  // Initial state from localStorage or empty object
  // const [dataToMap, setDataToMap] = useState(() => {
  //   const savedData = localStorage.getItem(localStorageKey);
  //   return savedData ? JSON.parse(savedData) : {};
  // });

  const [dataToMap, setDataToMap] = useState({});

  // Sync with reportData only if localStorage is empty
  useEffect(() => {
    if (!loading && reportData && Object.keys(dataToMap).length === 0) {
      const { id, ...other } = reportData;
      const mergedData = { ...other }; // Use only reportData initially if localStorage is empty
      // localStorage.setItem(localStorageKey, JSON.stringify(mergedData)); // Save to localStorage
      setDataToMap(mergedData); // Set state with merged data
    }
  }, [reportData, loading]);

  // Update localStorage on any input change
  const handleInputChange = (symbol, dataKey, value) => {
    setDataToMap((prevState) => {
      const updatedData = {
        ...prevState,
        [symbol]: {
          ...prevState[symbol],
          [dataKey]: {
            ...prevState[symbol][dataKey],
            value:
              value === "true"
                ? true
                : value === "false"
                ? false
                : parseFloat(value), // Handle boolean values
          },
        },
      };
      // localStorage.setItem(localStorageKey, JSON.stringify(updatedData)); // Persist changes in localStorage
      // return updatedData;
      debouncedSaveProgress(updatedData); // Pass the updated data directly
      return updatedData;
    });
  };

  const sendPutRequest = (updatedData) => {
    const keysRemoved = removeKeys(updatedData, dataToNotSend);
    const filteredData = filterEmptyObjects(keysRemoved);

    console.log("Sending PUT request with data:", filteredData);

    // Call the API with the updated data
    saveProgress({ socialKPI: filteredData })
      .then((response) => {
        console.log("PUT request successful:", response.data);
      })
      .catch((error) => {
        console.error("PUT request failed:", error);
      });
  };

  const debouncedSaveProgress = useCallback(
    debounce((updatedData) => {
      sendPutRequest(updatedData); // Use updated data here
    }, 500),
    []
  );

  const keyMapping = {
    totalMen: "Total Number of men in the workforce",
    totalWomen: "Total Number of women in the workforce",
    totalNonBinary:
      "Total Number of people in the workforce who identify as non-binary",
    foreignEmployees: "Number of foreign nationals in work-force",
    directEmployees: "Number of Direct employees",
    indirectEmployees:
      "Number of Indirect employees (contractors, outsourced etc)",
    averageTenure: "Average tenure of employees in the organisation",
    trainingHours: "Hours of training provided to employees",
    nonDiscriminatoryPolicy:
      "Do you have a Non-discriminatory policy in place?",
    trir: "TRIR (total recordable incident rate)",
    campusHires: "Total number of employees hired through campus placements",
  };

  const unitMapping = {
    "66d7562107b0aeb0cb66609a": "tCO2e",
    "66d7562207b0aeb0cb66609d": "million USD",
    "66d7562207b0aeb0cb6660a4": "m3",
    "66d7562207b0aeb0cb6660a9": "tonnes",
    "66d7562207b0aeb0cb6660ad": "MWh",
    "66d7562207b0aeb0cb6660af": "MWh eq",
    "66d7562207b0aeb0cb6660ab": "%",
    "66d7562207b0aeb0cb6660b3": "dB",
    "66d7562207b0aeb0cb6660b1": "ppm",
    "66d7562307b0aeb0cb6660b7": "Number",
    "66d7562307b0aeb0cb6660b9": "years",
    "66d7562307b0aeb0cb6660bb": "hours/year/employee",
    "66d7562307b0aeb0cb6660bd": "Yes/No",
    "66d7562307b0aeb0cb6660bf": "Incidients per 20000 hours worked",
  };

  // Create a mapping of unit IDs to unit names from unitsData
  const dynamicUnitMapping = unitsData?.data.reduce((acc, unit) => {
    acc[unit.id] = unit.name;
    return acc;
  }, {});

  const isMessages = true;

  const [modalState, setModalState] = useState({
    visible: false,
    top: 0,
    left: 0,
    symbol: null, // Track which symbol triggered the modal
    code: "",
    kpiName: "",
    alreadyTagged: [],
  });

  const handleAssignClick = (event, symbol, alreadyTagged) => {
    const buttonRect = event.target.getBoundingClientRect(); // Get button position
    const maxModalHeight = 550; // Max height of the modal

    const viewportHeight = window.innerHeight; // Total available viewport height
    const spaceBelow = viewportHeight - buttonRect.bottom; // Space below the button
    const spaceAbove = buttonRect.top; // Space above the button

    // Decide whether to position the modal above or below the button
    let topPosition;
    let modalHeight; // The height of the modal

    if (spaceBelow >= maxModalHeight) {
      // Enough space below the button, place the modal below
      topPosition = buttonRect.bottom;
      modalHeight = maxModalHeight; // Max height because there's enough space below
    } else if (spaceAbove >= maxModalHeight) {
      // Not enough space below, but enough space above, place the modal above
      topPosition = buttonRect.top - maxModalHeight;
      modalHeight = maxModalHeight; // Max height because there's enough space above
    } else if (spaceBelow > spaceAbove) {
      // More space below, use available space below
      topPosition = buttonRect.bottom;
      modalHeight = spaceBelow; // Set height to available space below
    } else {
      // More space above, use available space above
      topPosition = buttonRect.top - spaceAbove;
      modalHeight = spaceAbove; // Set height to available space above
    }

    // Adjust left position, ensuring the modal fits within the viewport width
    const modalWidth = 500; // Assume modal width
    const leftPosition = Math.min(
      buttonRect.right - modalWidth,
      window.innerWidth - modalWidth
    );

    // Set modal state with dynamically calculated top, left, and height
    setModalState({
      visible: true,
      top: topPosition,
      left: leftPosition,
      symbol, // Store the clicked symbol
      height: modalHeight, // Dynamically set the height based on available space
      code: symbol,
      kpiName: "socialKPI",
      alreadyTagged,
    });
  };

  // Hide the modal
  const closeModal = () => {
    setModalState((prev) => ({
      ...prev,
      visible: false,
      // top: 0,
      // left: 0,
      // symbol: null,
    }));
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="new-kpi-table-container">
        <div className="new-kpi-table">
          {/* Table Header */}
          <div className="new-kpi-table-header">
            <div className="new-kpi-table-row new-kpi-table-head-row">
              {headings?.map((heading, index) => (
                <div
                  key={index}
                  className={`new-kpi-table-cell header-cell ${
                    index === 0 || index > 2 ? "centered" : ""
                  }`}
                >
                  <div className="new-kpi-table-header-content">{heading}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Table Body */}
          {!loading && !unitsLoading ? (
            <div className="new-kpi-table-body">
              {Object?.keys(dataToMap)?.map((symbol) => {
                if (symbolsToNotMap.includes(symbol)) return null;

                // Check if any value is missing for the symbol
                const isSymbolMissingValue = Object.keys(
                  dataToMap[symbol]
                ).some(
                  (dataKey) =>
                    !dataPointsToNotMap.includes(dataKey) &&
                    (dataToMap[symbol][dataKey]?.value === undefined ||
                      dataToMap[symbol][dataKey]?.value === "" ||
                      dataToMap[symbol][dataKey]?.value === null)
                );

                // Determine background color for the symbol cell
                const symbolColor = !isAiGenerated
                  ? "inherit" // or "none" if you want no color applied, or any other fallback color
                  : isSymbolMissingValue
                  ? "" //#ffeded
                  : "#f6fffa";

                if (!dataToMap[symbol]?.assign) {
                  dataToMap[symbol] = {
                    ...dataToMap[symbol],
                    assign: { assignMembers: [] },
                  };
                }

                const assignMembers =
                  dataToMap[symbol]?.assign?.assignMembers || [];

                // Filter `teamMembers` to get only the members with IDs in `assignMembers`
                const assignedUsers =
                  allUsers?.filter((member) =>
                    assignMembers.includes(member.id)
                  ) || [];

                return (
                  <div
                    key={symbol}
                    className="new-kpi-table-row new-kpi-table-body-row"
                  >
                    {/* Symbol */}
                    <Tooltip
                      followCursor={true}
                      title={
                        <span style={{ fontSize: "12px", color: "black" }}>
                          {tooltipMapping[symbol] || "No description available"}
                        </span>
                      }
                      placement="top"
                      arrow
                      PopperProps={{
                        modifiers: [
                          {
                            name: "arrow",
                            options: {
                              padding: 5, // Offset the arrow a bit
                            },
                          },
                        ],
                      }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "#E6F5F1", // Tooltip background color
                            color: "black", // Text color
                            fontSize: "12px", // Font size
                            padding: "8px", // Optional: Adjust padding
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#E6F5F1", // Arrow color (same as background)
                          },
                        },
                      }}
                    >
                      <div
                        className="new-kpi-table-cell centered"
                        style={{
                          backgroundColor: symbolColor,
                          cursor: "pointer",
                        }}
                      >
                        <div className="new-kpi-table-cell-content">
                          {symbol}
                        </div>
                      </div>
                    </Tooltip>

                    {/* Data Points */}
                    <div className="new-kpi-table-cell">
                      {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                        if (dataPointsToNotMap.includes(dataKey)) return null;

                        // Check if the specific data point value is missing
                        const isDataPointMissing =
                          dataToMap[symbol][dataKey]?.value === undefined ||
                          dataToMap[symbol][dataKey]?.value === "" ||
                          dataToMap[symbol][dataKey]?.value === null;

                        // Determine the background color for this specific data point
                        const cellColor = isAiGenerated
                          ? isDataPointMissing
                            ? "" // Red if missing
                            : "#f6fffa" // No color if present
                          : "";

                        return (
                          <div
                            key={idx}
                            className="new-kpi-table-cell-content data"
                            style={{ backgroundColor: cellColor }} // Apply background color to individual data points
                          >
                            {keyMapping[dataKey] || dataKey}
                          </div>
                        );
                      })}
                    </div>

                    {/* Units */}
                    <div className="new-kpi-table-cell">
                      {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                        if (dataPointsToNotMap.includes(dataKey)) return null;

                        // Check if the specific data point value is missing for unit cell
                        const isDataPointMissing =
                          dataToMap[symbol][dataKey]?.value === undefined ||
                          dataToMap[symbol][dataKey]?.value === "" ||
                          dataToMap[symbol][dataKey]?.value === null;

                        // Determine the background color for this specific unit cell
                        const cellColor = isAiGenerated
                          ? isDataPointMissing
                            ? "" // Red if missing
                            : "#f6fffa" // No color if present
                          : "";

                        const unit =
                          dynamicUnitMapping[dataToMap[symbol][dataKey]?.unit];
                        const showUnit = unit === "0/1" ? "Yes/No" : unit;

                        return (
                          <div
                            key={idx}
                            className="new-kpi-table-cell-content data"
                            style={{ backgroundColor: cellColor }}
                          >
                            {showUnit || "N/A"}
                          </div>
                        );
                      })}
                    </div>

                    {/* Values */}
                    <div className="new-kpi-table-cell centered">
                      {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                        if (dataPointsToNotMap.includes(dataKey)) return null;

                        // Check if the specific value is missing for value cell
                        const isValueMissing =
                          dataToMap[symbol][dataKey]?.value === undefined ||
                          dataToMap[symbol][dataKey]?.value === "" ||
                          dataToMap[symbol][dataKey]?.value === null;

                        // Determine the background color for this specific value cell
                        const cellColor = isAiGenerated
                          ? isValueMissing
                            ? "" // Red if missing
                            : "#f6fffa" // No color if present
                          : "";

                        const unit =
                          unitMapping[dataToMap[symbol][dataKey].unit];
                        const value = dataToMap[symbol][dataKey].value;

                        if (unit === "Yes/No") {
                          return (
                            <div
                              className="new-kpi-table-cell-content input-wrapper"
                              key={idx}
                              style={{ backgroundColor: cellColor }} // Apply background color to Yes/No buttons
                            >
                              <button
                                className={`yes-no-btn yes-btn ${
                                  value === true ? "yes-btn-selected" : ""
                                }`}
                                onClick={() =>
                                  handleInputChange(symbol, dataKey, "true")
                                }
                              >
                                Yes
                              </button>
                              <button
                                className={`yes-no-btn no-btn ${
                                  value === false ? "no-btn-selected" : ""
                                }`}
                                onClick={() =>
                                  handleInputChange(symbol, dataKey, "false")
                                }
                              >
                                No
                              </button>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              key={idx}
                              className="new-kpi-table-cell-content input-wrapper"
                              style={{ backgroundColor: cellColor }} // Apply background color to individual input cells
                            >
                              <input
                                onWheel={(e) => e.target.blur()}
                                type="number"
                                value={
                                  dataToMap[symbol][dataKey]?.value !==
                                    undefined &&
                                  dataToMap[symbol][dataKey]?.value !== null
                                    ? dataToMap[symbol][dataKey].value
                                    : ""
                                }
                                className="input-field"
                                placeholder="Enter value"
                                onChange={(e) =>
                                  handleInputChange(
                                    symbol,
                                    dataKey,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          );
                        }
                      })}
                    </div>

                    {/* Messages */}
                    <div className="new-kpi-table-cell centered">
                      <div className="new-kpi-table-cell-content">
                        {dataToMap[symbol]?.assign?.assignMembers?.length >
                        0 ? (
                          <div className="messages-wrapper">
                            <div className="profile-images-container">
                              {/* Show the first 3 profile images */}
                              {/* {assignedUsers?.slice(0, 2)?.map((img) => (
                                <img
                                  key={`${constant.IMG_URL}/${img.profileImage}`}
                                  src={
                                    `${constant.IMG_URL}/${img.profileImage}` ??
                                    dummyUser
                                  }
                                  alt="Messaged"
                                  height={28}
                                  width={28}
                                />
                              ))} */}
                              {assignedUsers?.slice(0, 2)?.map((img) => (
                                <img
                                  key={`${constant.IMG_URL}/${img.profileImage}`}
                                  src={
                                    img.profileImage
                                      ? `${constant.IMG_URL}/${img.profileImage}`
                                      : emptyuser
                                  }
                                  onError={(e) => {
                                    e.target.src = emptyuser; // If image fails to load, fallback to empty user
                                  }}
                                  alt="Messaged"
                                  height={28}
                                  width={28}
                                />
                              ))}

                              {/* Show the count of remaining users if more than 3 */}
                              {assignedUsers.length > 2 && (
                                <div className="remaining-count">
                                  +{assignedUsers.length - 2}
                                </div>
                              )}
                            </div>
                            <div
                              onClick={(event) =>
                                handleAssignClick(event, symbol, assignMembers)
                              }
                              style={{ cursor: "pointer" }}
                              className="notifications-div"
                            >
                              <img
                                src={messages}
                                alt="Messaged"
                                height={24}
                                width={24}
                              />
                              {/* <span className="unread">2 Unread</span> */}
                              {dataToMap[symbol]?.unReadMessage > 0 && (
                                <span className="unread">
                                  {dataToMap[symbol]?.unReadMessage} Unread
                                </span>
                              )}
                            </div>
                          </div>
                        ) : (
                          <AssignButton
                            text="Assign"
                            onClick={(event) =>
                              handleAssignClick(event, symbol, assignMembers)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            // <p>LOADING...</p>
            <Loader />
          )}
        </div>
      </div>

      <AssignModal closeModal={closeModal} modalState={modalState} />
    </div>
  );
};

export default SocialKpi;
