import React, { useState } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { textFieldStyles } from "../../styles/inputStyles";

const PasswordInput = ({ value, onChange, error, helperText, placeholder }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      size="small"
      type={showPassword ? "text" : "password"}
      label={placeholder}
      variant="outlined"
      fullWidth
      required
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      // sx={{
      //   fontSize: "14px",
      //   "& .MuiOutlinedInput-root": {
      //     "&.Mui-focused fieldset": {
      //       borderColor: "#369D9C",
      //     },
      //   },
      //   "& .MuiInputLabel-root.Mui-focused": {
      //     color: "rgba(0, 0, 0, 0.60)",
      //     fontFamily: "Inter",
      //   },
      //   "& .MuiFormHelperText-root": {
      //     color: "red", // Custom helper text color
      //   },
      //   "& .MuiInputBase-input": {
      //     fontFamily: "Inter",
      //   },
      //   "& .MuiInputLabel-root": {
      //     fontFamily: "Inter",
      //     fontSize: "14px",
      //   },
      //   input: {
      //     fontSize: "14px",
      //   },
      // }}
      sx={textFieldStyles}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            style={{ position: "relative", zIndex: 2 }}
          >
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              style={{
                position: "relative",
                zIndex: 2,
                cursor: "pointer",
              }}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
