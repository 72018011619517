import { Dialog } from "@mui/material";
import React from "react";
import "./assignments.css";
import UserTagging from "./UserTagging";
import ChatSection from "./ChatSection";
import { useTaggingStore } from "./chatStore";
import { useParams } from "react-router-dom";
import api from "../../../../api";
import { reportEndpoints } from "../../../../api/endpoints/report.endpoints";

const AssignModal = ({ modalState, closeModal }) => {
  const { taggedUsers, clearStore } = useTaggingStore();
  const params = useParams();

  // const fetchReport = async () => {
  //   await api.get(reportEndpoints.getReport(params.id));
  // };

  const handleCloseModal = async () => {
    // await fetchReport();
    closeModal();
    clearStore();
  };

  return (
    <Dialog
      open={modalState.visible}
      onClose={handleCloseModal}
      aria-labelledby="assign-task-dialog"
      PaperProps={{
        style: {
          position: "absolute",
          width: "36.1875rem",
          maxHeight: "90vh", // Limit height to avoid overflow
          height: "auto",
          borderRadius: "8px",
          backgroundColor: "#f5f5f5",
          overflow: "hidden",
        },
      }}
    >
      <div className="assignments-container">
        <h2 className="assignments-title">Assign</h2>
        <UserTagging
          kpiName={modalState?.kpiName}
          code={modalState?.code}
          alreadyTagged={modalState.alreadyTagged}
        />
        {taggedUsers?.length > 0 && (
          <>
            <h2 className="assignments-title">Comments</h2>
            {/* <ChatSection /> */}
            <ChatSection
              reportId={params}
              kpiName={modalState?.kpiName}
              code={modalState?.code}
            />
          </>
        )}
      </div>
    </Dialog>
  );
};

export default AssignModal;
