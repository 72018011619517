import React from "react";
import {
  XYChart,
  AnimatedAreaSeries,
  AnimatedLineSeries,
  AnimatedGlyphSeries,
  Tooltip,
  Grid,
} from "@visx/xychart";
import { LinearGradient } from "@visx/gradient";
import { curveNatural, curveMonotoneX } from "@visx/curve";
import { Axis } from "@visx/xychart";
import { extent } from "d3-array";
import { convertToK } from "../../utils/convertToK";

const generateUniqueId = () =>
  `gradient-${Math.random().toString(36).substr(2, 9)}`;

export default function LineAreaGraph({
  width,
  height,
  lineColor,
  gradientFrom = "#8debff",
  gradientTo = "#ffffff",
  data,
  leftLabel = "",
  tooltipLabel,
  unit = "",
  denominator = "", // This now receives the denominator value string
}) {
  const gradientId = React.useMemo(() => generateUniqueId(), []);

  const newData = data || [
    { quarter: "Q1 2023", value: 10 },
    { quarter: "Q2 2023", value: 30 },
    { quarter: "Q3 2023", value: 45 },
    { quarter: "Q4 2023", value: 35 },
    { quarter: "Q1 2024", value: 80 },
    { quarter: "Q2 2024", value: 100 },
  ];

  const yDomain = extent(newData.map((d) => d.value));
  const yScaleDomain = [Math.min(0, yDomain[0]), yDomain[1]];

  // Map denominator values to their display names
  const denominatorLabels = {
    totalEmployees: "per employee",
    grossRevenue: "per million USD",
    totalSiteArea: "per m²",
    unitsProduced: "per unit production",
  };

  // Create label using the mapped denominator name
  const yAxisLabel = denominator
    ? `tonnes ${denominatorLabels[denominator] || denominator}`
    : leftLabel;

  return (
    <XYChart
      xScale={{ type: "band", padding: 0.1 }}
      yScale={{ type: "linear", domain: yScaleDomain, nice: true }}
      height={height}
      width={width}
      margin={{
        top: 40,
        right: 0,
        bottom: 30,
        left: yAxisLabel !== "" ? 50 : 30,
      }}
    >
      <LinearGradient id={gradientId} from={gradientFrom} to={gradientTo} />

      <Grid
        columns={true}
        rows={true}
        numTicks={4}
        lineStyle={{
          accentColor: "#f3f3f3",
          width: 1,
          dotted: true,
          strokeDasharray: "4 4",
          columnRuleStyle: "solid",
          rowRuleStyle: "dashed",
        }}
      />

      <AnimatedAreaSeries
        dataKey="Area"
        data={newData}
        xAccessor={(d) => d.quarter}
        yAccessor={(d) => d.value}
        fillOpacity={0.4}
        fill={`url(#${gradientId})`}
        curve={curveMonotoneX}
      />

      <AnimatedLineSeries
        dataKey="Line"
        data={newData}
        xAccessor={(d) => d.quarter}
        yAccessor={(d) => d.value}
        stroke={lineColor}
        curve={curveMonotoneX}
      />

      <AnimatedGlyphSeries
        dataKey="Points"
        data={newData}
        xAccessor={(d) => d.quarter}
        yAccessor={(d) => d.value}
        size={75}
        renderGlyph={(glyphProps) => {
          const { x, y } = glyphProps;
          return <circle cx={x} cy={y} r={4} fill={lineColor} />;
        }}
      />

      <Axis
        orientation="bottom"
        hideAxisLine
        hideTicks
        tickLabelProps={{
          fontSize: 9,
        }}
      />

      <Axis
        orientation="left"
        hideAxisLine
        label={yAxisLabel}
        hideTicks
        numTicks={4}
        tickLabelProps={{
          fontSize: 9,
        }}
        labelProps={{
          fontSize: 9,
          fontWeight: 500,
          dx: "-1.5em",
        }}
        tickFormat={convertToK}
      />

      <Tooltip
        offsetLeft={-120}
        offsetTop={0}
        renderTooltip={({ tooltipData }) => (
          <div
            style={{
              padding: "8px",
              fontWeight: 400,
              fontSize: 11,
              display: "flex",
              gap: 8,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: lineColor,
                height: 12,
                width: 12,
              }}
            ></div>
            <div>
              {tooltipLabel} {tooltipData?.nearestDatum?.datum.value} {unit}
            </div>
          </div>
        )}
      />
    </XYChart>
  );
}
