/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import "./assignments.css";
import "./chats.css";
import paperclip from "../../../../assets/icons/paperclip.svg";
import sendIcon from "../../../../assets/icons/sendIcon.svg";
import coloredSend from "../../../../assets/icons/coloredSend.svg";
import topUser from "../../../../assets/images/topUser.png";
import pdf from "../../../../assets/icons/pdf.svg";
import UploadModal from "./UploadModal";
import useOrganizationContext from "../../../../context/OrgContext";

import axios from "axios";
import { initializeSocket } from "../../../../api/socket/socket";
import constant from "../../../../constant";
import api from "../../../../api";
import { useTaggingStore } from "./chatStore";

const ChatSection = ({ reportId, kpiName, code }) => {
  const { allUsers, fetchUsers } = useTaggingStore();

  const { userData } = useOrganizationContext();
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [attachedFile, setAttachedFile] = useState(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const chatEndRef = useRef(null);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const getUserInfo = (senderId) => {
    const user = allUsers?.find((user) => user.id === senderId);
    return {
      name: user?.name || senderId,
      profileImage: user?.profileImage
        ? `${constant.IMG_URL}/${user.profileImage}`
        : topUser,
    };
  };

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(
          `${constant.BACKEDN_BASE_URL}api/chat/messages`,
          {
            params: {
              reportId: reportId?.id,
              code,
              kpiName,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const formattedMessages = response.data.data.map((msg) => ({
          id: msg.id,
          text: msg.message,
          sender: msg.sender,
          time: new Date(msg.createdAt).toLocaleTimeString(),
          me: msg.sender === userData?.id,
          icon: topUser,
          file: msg.attachment?.[0]
            ? {
                name: msg.attachment[0].name,
                key: msg.attachment[0].key,
                isUrl: true,
                chatId: msg.id,
              }
            : null,
        }));

        setMessages(formattedMessages.reverse());
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    if (reportId?.id && code && kpiName) {
      fetchMessages();
    }
  }, [reportId?.id, code, kpiName, userData?.id]);

  useEffect(() => {
    const cleanup = initializeSocket((message) => {
      setMessages((prev) => [
        ...prev,
        {
          id: message.id || Date.now(),
          text: message.message,
          sender: message.sender,
          time: new Date().toLocaleTimeString(),
          me: message.sender === userData?.id,
          icon: topUser,
          file: message.attachment?.[0]
            ? {
                name: message.attachment[0].name,
                key: message.attachment[0].key,
                isUrl: true,
                chatId: message.id,
              }
            : null,
        },
      ]);
    });

    // return () => {
    //   if (cleanup) cleanup();
    // };
  }, [userData?.id]);

  const handleMessageInput = (e) => {
    setCurrentMessage(e.target.value);
  };

  const sendMessageToAPI = async (messageText, file = null) => {
    try {
      const formData = new FormData();
      formData.append("message", messageText);
      formData.append("reportId", reportId?.id);
      formData.append("kpiName", kpiName);
      formData.append("code", code);

      if (file) {
        formData.append("attachment", file);
      }

      await axios.post(`${constant.BACKEDN_BASE_URL}api/chat/send`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleSendMessage = async () => {
    if (currentMessage.trim() !== "" || attachedFile) {
      const newMessage = {
        id: messages.length + 1,
        text: currentMessage,
        sender: userData?.id,
        time: new Date().toLocaleTimeString(),
        me: true,
        file: attachedFile
          ? {
              name: attachedFile.name,
              blob: attachedFile,
              isFile: true,
            }
          : null,
      };

      setMessages((prev) => [...prev, newMessage]);
      await sendMessageToAPI(currentMessage, attachedFile);
      setCurrentMessage("");
      setAttachedFile(null);
    }
  };

  const handleFileSelect = async (files) => {
    try {
      const file = files[0];
      const formData = new FormData();
      formData.append("reportId", reportId?.id);
      formData.append("kpiName", kpiName);
      formData.append("code", code);
      formData.append("attachment", file);

      await axios.post(`${constant.BACKEDN_BASE_URL}api/chat/send`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setMessages((prev) => [
        ...prev,
        {
          id: prev.length + 1,
          text: "",
          sender: userData?.id,
          time: new Date().toLocaleTimeString(),
          me: true,
          file: {
            name: file.name,
            blob: file,
            isFile: true,
          },
        },
      ]);

      setUploadModalOpen(false);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // const getFileUrl = (file) => {
  //   if (!file) return "";

  //   if (file.isUrl) {
  //     return `${constant.BACKEDN_BASE_URL}api/chat/file?reportId=${reportId?.id}&code=${code}&kpiName=${kpiName}&fileId=${file.key}&chatId=${file.chatId}`;
  //   }

  //   if (file.isFile && file.blob) {
  //     return URL.createObjectURL(file.blob);
  //   }

  //   return "";
  // };

  const handleFileClick = async (e, file) => {
    e.preventDefault();
    if (!file) return;

    // For uploaded files that haven't been saved yet
    if (file.isFile && file.blob) {
      window.open(URL.createObjectURL(file.blob), "_blank");
      return;
    }

    // For files from the server
    if (file.isUrl) {
      try {
        // Make request to get file with axios instance that has auth headers
        const response = await api.get(`/chat/file`, {
          params: {
            reportId: reportId?.id,
            code,
            kpiName,
            fileId: file.key,
            chatId: file.chatId,
          },
          responseType: "blob", // Important for handling PDF files
        });

        // Create blob URL and open in new tab
        const blob = new Blob([response.data], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, "_blank");

        // Clean up blob URL after opening
        setTimeout(() => {
          URL.revokeObjectURL(blobUrl);
        }, 100);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    }
  };

  return (
    <>
      <div className="chat-section">
        {messages.map((msg) => (
          <div
            key={msg.id}
            className={`message-container ${msg.me ? "me" : "other"}`}
          >
            {!msg.me && (
              <div className="message-sender">
                <img
                  src={getUserInfo(msg.sender).profileImage}
                  height={22}
                  width={22}
                  alt="User"
                  style={{
                    borderRadius: "50%",
                    objectFit: "cover",
                    backgroundColor: "#fff", // Add white background
                  }}
                />
                <div className="message-sender_details">
                  <span>{getUserInfo(msg.sender).name}</span>
                  {msg.sender && (
                    <div
                      style={{
                        height: "2.3px",
                        width: "2.3px",
                        backgroundColor: "#000",
                        borderRadius: "50%",
                        margin: "0 6px",
                        alignSelf: "center",
                      }}
                    />
                  )}
                  <span className="message-time">{msg.time}</span>
                </div>
              </div>
            )}
            {/* Changed UI */}
            {msg.me && (
              <div className="message-sender me">
                <img
                  src={getUserInfo(userData?.id).profileImage}
                  height={22}
                  width={22}
                  alt="User"
                  style={{
                    borderRadius: "50%",
                    objectFit: "cover",
                    backgroundColor: "#fff",
                  }}
                />
                <div className="message-sender_details">
                  {msg.me && <div className="message-time">{msg.time}</div>}
                  {msg.sender && (
                    <div
                      style={{
                        height: "2.3px",
                        width: "2.3px",
                        backgroundColor: "#000",
                        borderRadius: "50%",
                        margin: "0 6px",
                        alignSelf: "center",
                      }}
                    />
                  )}

                  <span>{getUserInfo(userData?.id).name}</span>
                </div>
              </div>
            )}
            {/* Changed UI  - Unchange it by removing above code and uncomment the time below.*/}

            <div className="message-bubble">{msg.text}</div>

            {msg.file && (
              <div
                className={`attached-file ${msg.me ? "me-file" : "other-file"}`}
                style={{
                  alignSelf: msg.me ? "flex-end" : "flex-start",
                }}
              >
                <img src={pdf} alt="PDF" width={32} height={32} />
                <a
                  // href={getFileUrl(msg.file)}
                  href="#"
                  onClick={(e) => handleFileClick(e, msg.file)}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {msg.file.name}
                </a>
              </div>
            )}

            {/* {msg.me && <div className="message-time">{msg.time}</div>} */}
          </div>
        ))}
        <div ref={chatEndRef} />
      </div>

      <div className="comment-input_container">
        <input
          type="text"
          className="comment-input"
          placeholder="Type a message..."
          onChange={handleMessageInput}
          value={currentMessage}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
        />

        <div className="comment-actions">
          <button type="button" onClick={() => setUploadModalOpen(true)}>
            <img src={paperclip} height={28} width={28} alt="Attach" />
          </button>
          <button type="button" onClick={handleSendMessage}>
            {currentMessage?.trim() !== "" || attachedFile ? (
              <img src={coloredSend} height={28} width={28} alt="Send" />
            ) : (
              <img src={sendIcon} height={28} width={28} alt="Send" />
            )}
          </button>
        </div>
      </div>

      <UploadModal
        isOpen={uploadModalOpen}
        onClose={() => setUploadModalOpen(false)}
        onFileSelect={handleFileSelect}
      />
    </>
  );
};

export default ChatSection;
