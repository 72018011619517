// /* eslint-disable no-use-before-define */
// import React, { useState } from "react";
// import Pie from "@visx/shape/lib/shapes/Pie";
// import { scaleOrdinal } from "@visx/scale";
// import { Group } from "@visx/group";
// import { animated, useTransition, to } from "@react-spring/web";
// import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
// import { localPoint } from "@visx/event";
// import { LegendOrdinal } from "@visx/legend";

// // Accessor functions
// const value = (d) => d.value;

// const defaultMargin = { top: 10, right: 0, bottom: 10, left: 0 };

// export default function HalfDoughnutChart({
//   data,
//   colors,
//   width,
//   height,
//   margin = defaultMargin,
//   animate = true,
//   unit = "", // Optional unit
// }) {
//   // Tooltip logic
//   const {
//     tooltipOpen,
//     tooltipLeft,
//     tooltipTop,
//     showTooltip,
//     hideTooltip,
//     tooltipData,
//   } = useTooltip();
//   const { containerRef, TooltipInPortal } = useTooltipInPortal({
//     scroll: true,
//   });

//   // Calculate the total value of the dataset
//   const totalValue = data.reduce((acc, d) => acc + d.value, 0);

//   if (width < 10) return null;

//   const innerWidth = width - margin.left - margin.right;
//   const innerHeight = height - margin.top - margin.bottom;
//   const radius = Math.min(innerWidth, innerHeight) / 2;
//   const centerY = height / 2; // Center based on the full height of the SVG
//   const centerX = width / 2; // Center based on the full width of the SVG
//   const innerRadius = radius * 0.8;

//   // Color scale
//   const colorScale = scaleOrdinal({
//     domain: data.map((d) => d.label),
//     range: colors,
//   });

//   return (
//     <div style={{ position: "relative" }} ref={containerRef}>
//       <svg
//         style={{
//           display: "block",
//           margin: "auto",
//         }}
//         width={width}
//         height={height}
//       >
//         <Group top={centerY} left={centerX}>
//           <Pie
//             data={data}
//             pieValue={value}
//             outerRadius={radius}
//             innerRadius={innerRadius}
//             startAngle={-Math.PI / 2} // Start angle for the half doughnut (left side)
//             endAngle={Math.PI / 2} // End angle for the half doughnut (right side)
//           >
//             {(pie) => (
//               <AnimatedPie
//                 {...pie}
//                 animate={animate}
//                 getKey={(arc) => arc.data.label}
//                 getColor={(arc) => colorScale(arc.data.label)}
//                 getPercentage={(arc) =>
//                   ((arc.data.value / totalValue) * 100).toFixed(2)
//                 }
//                 onHoverDatum={(arc, event) => {
//                   const eventSvgCoords = localPoint(event);
//                   showTooltip({
//                     tooltipTop: eventSvgCoords.y,
//                     tooltipLeft: eventSvgCoords.x,
//                     tooltipData: {
//                       ...arc.data,
//                       percentage: ((arc.data.value / totalValue) * 100).toFixed(
//                         2
//                       ),
//                     },
//                   });
//                 }}
//                 onLeaveDatum={hideTooltip}
//               />
//             )}
//           </Pie>
//         </Group>
//       </svg>

//       {/* Tooltip */}
//       {tooltipOpen && tooltipData && (
//         <TooltipInPortal
//           top={tooltipTop}
//           left={tooltipLeft}
//           style={{
//             ...defaultStyles,
//             backgroundColor: "white",
//             padding: 12,
//             boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
//             borderRadius: "4px",
//             color: "black",
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             fontSize: 11,
//           }}
//         >
//           <div style={{ display: "flex", gap: "10px" }}>
//             <div
//               style={{
//                 backgroundColor: colorScale(tooltipData.label),
//                 height: 15,
//                 width: 15,
//               }}
//             ></div>
//             <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
//               <div>
//                 <span style={{ color: "#BDBDBD" }}>{tooltipData.label}</span>
//               </div>
//               <div
//                 style={{
//                   height: "3px",
//                   width: "3px",
//                   borderRadius: "100%",
//                   backgroundColor: "#BDBDBD",
//                 }}
//               ></div>
//               <div style={{ color: "#717171" }}>{tooltipData.percentage}%</div>
//               <div
//                 style={{
//                   height: "3px",
//                   width: "3px",
//                   borderRadius: "100%",
//                   backgroundColor: "#BDBDBD",
//                 }}
//               ></div>
//               <div>
//                 {tooltipData.value} {unit}
//               </div>
//             </div>
//           </div>
//         </TooltipInPortal>
//       )}

//       <div style={{ width: "100%", marginTop: "-30px" }}>
//         <LegendOrdinal
//           scale={colorScale}
//           direction="row"
//           itemMargin="0 5px"
//           shape="circle"
//           shapeHeight={10}
//           shapeWidth={10}
//           style={{
//             display: "flex",
//             alignItems: "center",
//             fontSize: "11px",
//             fontWeight: "0.687rem",
//             flexWrap: "wrap",
//             gap: "10px",
//           }}
//         />
//       </div>
//     </div>
//   );
// }

// // react-spring transition definitions
// const fromLeaveTransition = ({ endAngle }) => ({
//   startAngle: -Math.PI,
//   endAngle: -Math.PI,
//   opacity: 0,
// });

// const enterUpdateTransition = ({ startAngle, endAngle }) => ({
//   startAngle,
//   endAngle,
//   opacity: 1,
// });

// function AnimatedPie({
//   animate,
//   arcs,
//   path,
//   getKey,
//   getColor,
//   getPercentage,
//   onHoverDatum,
//   onLeaveDatum,
// }) {
//   const transitions = useTransition(arcs, {
//     from: animate ? fromLeaveTransition : enterUpdateTransition,
//     enter: enterUpdateTransition,
//     update: enterUpdateTransition,
//     leave: animate ? fromLeaveTransition : enterUpdateTransition,
//     keys: getKey,
//   });

//   return transitions((props, arc, { key }) => {
//     const [centroidX, centroidY] = path.centroid(arc);

//     return (
//       <g
//         key={key}
//         onMouseEnter={(event) => onHoverDatum(arc, event)}
//         onMouseMove={(event) => onHoverDatum(arc, event)}
//         onMouseLeave={onLeaveDatum}
//       >
//         <animated.path
//           d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
//             path({
//               ...arc,
//               startAngle,
//               endAngle,
//             })
//           )}
//           fill={getColor(arc)}
//         />
//       </g>
//     );
//   });
// }

/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import Pie from "@visx/shape/lib/shapes/Pie";
import { scaleOrdinal } from "@visx/scale";
import { Group } from "@visx/group";
import { animated, useTransition, to } from "@react-spring/web";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { LegendOrdinal } from "@visx/legend";

// Accessor functions
const value = (d) => d.value;

const defaultMargin = { top: 10, right: 0, bottom: 10, left: 0 };

export default function HalfDoughnutChart({
  data,
  colors,
  width,
  height,
  margin = defaultMargin,
  animate = true,
  unit = "",
}) {
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    showTooltip,
    hideTooltip,
    tooltipData,
  } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  // Check if data is empty or all values are 0
  const isEmpty = !data?.length || data.every((item) => !item.value);

  // If empty, create placeholder data
  const chartData = isEmpty
    ? [
        { label: "Empty", value: 1 },
        { label: "Empty", value: 1 },
      ]
    : data;

  const totalValue = chartData.reduce((acc, d) => acc + d.value, 0);

  if (width < 10) return null;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = height / 2;
  const centerX = width / 2;
  const innerRadius = radius * 0.8;

  const colorScale = scaleOrdinal({
    domain: chartData.map((d) => d.label),
    range: isEmpty ? ["#E5E5E5", "#F0F0F0"] : colors,
  });

  // Container style with blur effect
  const containerStyle = {
    position: "relative",
    opacity: isEmpty ? 0.6 : 1,
    filter: isEmpty ? "blur(1px)" : "none",
    pointerEvents: isEmpty ? "none" : "auto",
    transition: "all 0.3s ease-in-out",
  };

  return (
    <div style={containerStyle} ref={containerRef}>
      <svg
        style={{
          display: "block",
          margin: "auto",
        }}
        width={width}
        height={height}
      >
        <Group top={centerY} left={centerX}>
          <Pie
            data={chartData}
            pieValue={value}
            outerRadius={radius}
            innerRadius={innerRadius}
            startAngle={-Math.PI / 2}
            endAngle={Math.PI / 2}
          >
            {(pie) => (
              <AnimatedPie
                {...pie}
                animate={animate}
                getKey={(arc) => arc.data.label}
                getColor={(arc) => colorScale(arc.data.label)}
                getPercentage={(arc) =>
                  ((arc.data.value / totalValue) * 100).toFixed(2)
                }
                onHoverDatum={(arc, event) => {
                  if (!isEmpty) {
                    const eventSvgCoords = localPoint(event);
                    showTooltip({
                      tooltipTop: eventSvgCoords.y,
                      tooltipLeft: eventSvgCoords.x,
                      tooltipData: {
                        ...arc.data,
                        percentage: (
                          (arc.data.value / totalValue) *
                          100
                        ).toFixed(2),
                      },
                    });
                  }
                }}
                onLeaveDatum={hideTooltip}
              />
            )}
          </Pie>
        </Group>
      </svg>

      {!isEmpty && tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            backgroundColor: "white",
            padding: 12,
            boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
            borderRadius: "4px",
            color: "black",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              style={{
                backgroundColor: colorScale(tooltipData.label),
                height: 15,
                width: 15,
              }}
            ></div>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div>
                <span style={{ color: "#BDBDBD" }}>{tooltipData.label}</span>
              </div>
              <div
                style={{
                  height: "3px",
                  width: "3px",
                  borderRadius: "100%",
                  backgroundColor: "#BDBDBD",
                }}
              ></div>
              <div style={{ color: "#717171" }}>{tooltipData.percentage}%</div>
              <div
                style={{
                  height: "3px",
                  width: "3px",
                  borderRadius: "100%",
                  backgroundColor: "#BDBDBD",
                }}
              ></div>
              <div>
                {tooltipData.value} {unit}
              </div>
            </div>
          </div>
        </TooltipInPortal>
      )}

      <div style={{ width: "100%", marginTop: "-30px" }}>
        <LegendOrdinal
          scale={colorScale}
          direction="row"
          itemMargin="0 5px"
          shape="circle"
          shapeHeight={10}
          shapeWidth={10}
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "11px",
            fontWeight: "0.687rem",
            flexWrap: "wrap",
            gap: "10px",
          }}
        />
      </div>
    </div>
  );
}

// Unchanged transition definitions
const fromLeaveTransition = ({ endAngle }) => ({
  startAngle: -Math.PI,
  endAngle: -Math.PI,
  opacity: 0,
});

const enterUpdateTransition = ({ startAngle, endAngle }) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

function AnimatedPie({
  animate,
  arcs,
  path,
  getKey,
  getColor,
  getPercentage,
  onHoverDatum,
  onLeaveDatum,
}) {
  const transitions = useTransition(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });

  return transitions((props, arc, { key }) => {
    const [centroidX, centroidY] = path.centroid(arc);

    return (
      <g
        key={key}
        onMouseEnter={(event) => onHoverDatum(arc, event)}
        onMouseMove={(event) => onHoverDatum(arc, event)}
        onMouseLeave={onLeaveDatum}
      >
        <animated.path
          d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
            path({
              ...arc,
              startAngle,
              endAngle,
            })
          )}
          fill={getColor(arc)}
        />
      </g>
    );
  });
}
