import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import base from "../../../assets/sustainabilityReport/baseSector.svg";
import envGrade from "../../../assets/sustainabilityReport/envGrade.svg";
import govGrade from "../../../assets/sustainabilityReport/governanceGrade.svg";
import socialGrade from "../../../assets/sustainabilityReport/socialGrade.svg";
import { gradeColors } from "../gradeColors";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import RadialPieChart from "../RadialPieChart";
import {
  formattedRadialData,
  transformActionableData,
} from "../../../utils/reportPage";
import PartitionChart from "../../Radial";

const BaseSectorCard = ({ icon, title, grade }) => {
  const textColor = gradeColors[grade?.[0]];

  return (
    <Box
      sx={{
        height: "8.7rem",
        width: "100%",
        bgcolor: "#fff",
        p: "1.25rem",
        borderRadius: "12px",
      }}
    >
      <img
        src={icon}
        alt={title}
        height={30}
        width={30}
        style={{ marginBottom: "6px" }}
      />
      <h4 className="base-sector-card_title">{title}</h4>
      <Typography variant="h5" sx={{ color: textColor, fontSize: "20px" }}>
        {grade}
      </Typography>
    </Box>
  );
};

const BaseSectorZeroState = ({ report }) => {
  const { basicSectorSpecificKPI, socialKPI, environmentKPI } = report;
  console.log(report);
  const cardsData = [
    {
      id: 0,
      icon: base,
      title: "Base Performance Grade",

      grade: report?.basicSectorSpecificKPI?.grade,
    },
    {
      id: 1,
      icon: envGrade,
      title: "Environment Performance Grade",
      grade: report?.environmentKPI?.grade,
    },
    {
      id: 2,
      icon: socialGrade,
      title: "Social Performance Grade",

      grade: report?.socialKPI?.grade,
    },
    {
      id: 3,
      icon: govGrade,
      title: "Governance Performance Grade",

      grade: report?.governanceKPI?.grade,
    },
  ];

  const intensitiesTableHeading = [
    "X",
    "Total waste generated (tonnes)",
    "GHG emissions: Scope 1 and Scope 2 (tCO2e)",
    "Water consumption (m³)",
  ];

  const totalEmployees =
    socialKPI?.S1?.totalMen?.value +
    socialKPI?.S1?.totalWomen?.value +
    socialKPI?.S1?.totalNonBinary?.value;
  const scope1And2 =
    environmentKPI?.E1?.scope1Emissions?.value +
    environmentKPI?.E1?.scope2Emissions?.value;

  const totalWaste =
    basicSectorSpecificKPI?.B3?.totalHazardousWaste?.value +
    basicSectorSpecificKPI?.B4?.totalNonHazardousWaste?.value;

  const intensitiesTableData = [
    {
      x: "per employee",
      totalWaste: (totalWaste / totalEmployees).toFixed(2),
      ghg: (scope1And2 / totalEmployees).toFixed(2),
      waterConsumption: (
        basicSectorSpecificKPI?.B2?.totalWaterConsumption?.value /
        totalEmployees
      ).toFixed(2),
    },
    {
      x: "per Million USD of revenue generated",
      totalWaste: (
        totalWaste / basicSectorSpecificKPI?.B1?.grossRevenue?.value
      ).toFixed(2),
      ghg: (
        scope1And2 / basicSectorSpecificKPI?.B1?.grossRevenue?.value
      )?.toFixed(2),
      waterConsumption: (
        basicSectorSpecificKPI?.B2?.totalWaterConsumption?.value /
        basicSectorSpecificKPI?.B1?.grossRevenue?.value
      ).toFixed(2),
    },
    {
      x: "per m² of GFA",
      totalWaste: (
        totalWaste / report?.organizationDetails?.totalSiteArea
      )?.toFixed(2),
      ghg: (scope1And2 / report?.organizationDetails?.totalSiteArea).toFixed(2),
      waterConsumption: (
        basicSectorSpecificKPI?.B2?.totalWaterConsumption?.value /
        report?.organizationDetails?.totalSiteArea
      ).toFixed(2),
    },
    {
      x: "per unit production",
      totalWaste: (
        totalWaste / report?.organizationDetails?.unitsProduced?.value
      ).toFixed(2),
      ghg: (
        scope1And2 / report?.organizationDetails?.unitsProduced?.value
      )?.toFixed(2),
      waterConsumption: (
        basicSectorSpecificKPI?.B2?.totalWaterConsumption?.value /
        report?.organizationDetails?.unitsProduced?.value
      ).toFixed(2),
    },
  ];

  const formattedActionableData = transformActionableData(
    report?.actionableInsights
  );

  const gradeData = [
    { grade: "AA", score: "> 75" },
    { grade: "AB", score: "70-75" },
    { grade: "BB", score: "65-70" },
    { grade: "BC", score: "60-65" },
    { grade: "CC", score: "55-60" },
    { grade: "CD", score: "50-55" },
    { grade: "DD", score: "40-50" },
    { grade: "FF", score: "< 40" },
  ];

  const kpiTableHeadings = ["KPI Code", "KPI", "Unit"];

  const kpiTableData = [
    {
      code: "ONG-a-B1",
      kpi: "GHG emissions intensity",
      unit: "(tCO2e / USD Million Revenue)",
    },
    {
      code: "ONG-a-B2",
      kpi: "Water Intensity",
      unit: "(m³/ USD Million Revenue)",
    },
    {
      code: "ONG-a-B3",
      kpi: "Hazardous waste Intensity",
      unit: "(Total Hazardous Waste in tonnes / USD Million Revenue)",
    },
    {
      code: "ONG-a-B4",
      kpi: "Non-hazardous waste Intensity",
      unit: "(Total Non-Hazardous Waste in tonnes / USD Million Revenue)",
    },
    {
      code: "ONG-a-B5",
      kpi: "Sustainability budget as a percentage of Revenue",
      unit: "%",
    },
    {
      code: "ONG-a-B6",
      kpi: "Percentage of electricity consumed that comes from clean sources plus purchase of REC certificates",
      unit: "%",
    },
    {
      code: "ONG-a-E1",
      kpi: "Scope 1 and Scope 2 - CO2e emissions",
      unit: "tCO2e/year",
    },
    { code: "ONG-a-E2", kpi: "Scope 3 - CO2e emissions", unit: "tCO2e/year" },
    { code: "ONG-a-E3", kpi: "Amount of Water Consumed", unit: "m³/year" },
    {
      code: "ONG-a-E4",
      kpi: "Percentage of total waste disposed to landfill",
      unit: "%",
    },
    {
      code: "ONG-a-E5",
      kpi: "NOx released into the environment",
      unit: "tonnes/year",
    },
    {
      code: "ONG-a-E6",
      kpi: "SOx released in the environment",
      unit: "tonnes/year",
    },
    {
      code: "ONG-a-E7",
      kpi: "Threshold H2S concentration for the flare technology in use",
      unit: "ppm",
    },
    { code: "ONG-a-E8", kpi: "LA90 Noise level", unit: "dB" },
    {
      code: "ONG-a-E9",
      kpi: "Percentage of waste water treated before being disposed",
      unit: "%",
    },
    { code: "ONG-a-S1", kpi: "Gender Ratio", unit: "Ratio" },
    { code: "ONG-a-S2", kpi: "Percentage of foreign employees", unit: "%" },
    {
      code: "ONG-a-S3",
      kpi: "Percent of Direct to Indirect Employees",
      unit: "Ratio",
    },
    {
      code: "ONG-a-S4",
      kpi: "Average tenure of employees in the organisation",
      unit: "in years",
    },
    {
      code: "ONG-a-S5",
      kpi: "Hours of training provided to employees",
      unit: "hours/year/employee",
    },
    {
      code: "ONG-a-S6",
      kpi: "Non-discriminatory policy in place",
      unit: "0/1",
    },
    { code: "ONG-a-S7", kpi: "TRIR", unit: "Number/year" },
    {
      code: "ONG-a-S8",
      kpi: "Total number of employees hired through campus placements",
      unit: "Number/year",
    },
    {
      code: "ONG-a-G1",
      kpi: "Tax Relief/Incentives/Subsidies Received from the Government",
      unit: "0/1",
    },
    {
      code: "ONG-a-G2",
      kpi: "Percentage of pension salary contributed by employer",
      unit: "%",
    },
    {
      code: "ONG-a-G3",
      kpi: "Amount spent on local suppliers as a percentage of Gross Revenue",
      unit: "%",
    },
    {
      code: "ONG-a-G4",
      kpi: "Amount spent on suppliers having registered business less than 5 years as a percentage of Gross Revenue",
      unit: "%",
    },
    {
      code: "ONG-a-G5",
      kpi: "Amount spent on local communities as a percentage of Gross Revenue",
      unit: "%",
    },
    {
      code: "ONG-a-G6",
      kpi: "Amount spent on innovative technologies as a percentage of Gross Revenue",
      unit: "%",
    },
    { code: "ONG-a-G7", kpi: "Ethics policy in place", unit: "0/1" },
    { code: "ONG-a-G8", kpi: "Percentage of resolved complaints", unit: "%" },
    { code: "ONG-a-G9", kpi: "Number of Board members", unit: "Number" },
    {
      code: "ONG-a-G10",
      kpi: "Amount spent on CSR activities as a percentage of Gross Revenue",
      unit: "%",
    },
  ];

  const formattedRadialChartData = formattedRadialData(report);

  return (
    <>
      <Grid container spacing={2.5} sx={{ mb: "20px" }}>
        {cardsData?.map((item) => (
          <Grid key={item.id} item xs={3}>
            <BaseSectorCard {...item} />
          </Grid>
        ))}
      </Grid>

      {/* <Grid container spacing={2.5}>
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2.5,
          }}
        >
          <Box
            sx={{
              height: "142px",
              width: "100%",
              p: "20px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#fff",
              borderRadius: "12px",
            }}
          >
            <div>
              <h2>Overall Grade</h2>
              <h3
                style={{
                  fontWeight: 500,
                  fontSize: "20px",
                  color: gradeColors[report?.grade?.[0]],
                  marginTop: "24px",
                }}
              >
                {report?.grade}
              </h3>
            </div>
          </Box>

          <Box
            sx={{
              height: "24rem",
              width: "100%",
              p: 2,

              backgroundColor: "#fff",
              borderRadius: "12px",
            }}
          >
            <h2>Score Break down</h2>
            <Typography sx={{ fontSize: "11px", marginBottom: 2 }}>
              *Total number of KPIs reported:{" "}
              {`${report?.completedStatus?.done}/${report?.completedStatus?.total}`}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PartitionChart data={formattedRadialChartData} />
            </div>
          </Box>
        </Grid>

        <Grid item xs={9}>
          <Grid container spacing={2.5}>
            <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  p: 2,
                  height: "21rem",
                  bgcolor: "white",
                  borderRadius: "12px",
                }}
              >
                <h2>Intensities Table</h2>

                <div
                  style={{
                    backgroundColor: "#fcfcfc",
                    padding: "6px",
                    borderRadius: "12px",
                  }}
                >
                  <Grid container>
                    {intensitiesTableHeading.map((item) => (
                      <Grid
                        key={item}
                        item
                        xs={3}
                        sx={{
                          mt: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "0.562rem",
                          textAlign: "center",
                          fontWeight: 500,
                          p: "5px",
                        }}
                      >
                        {item}
                      </Grid>
                    ))}
                  </Grid>

                  <Grid container sx={{ borderCollapse: "collapse" }}>
                    {intensitiesTableData.map((item, itemIndex) =>
                      Object.entries(item).map(
                        ([key, value], entryIndex, entries) => {
                          const isTopLeft = itemIndex === 0 && entryIndex === 0;
                          const isTopRight =
                            itemIndex === 0 &&
                            entryIndex === entries.length - 1;
                          const isBottomLeft =
                            itemIndex === intensitiesTableData.length - 1 &&
                            entryIndex === 0;
                          const isBottomRight =
                            itemIndex === intensitiesTableData.length - 1 &&
                            entryIndex === entries.length - 1;

                          return (
                            <Grid
                              item
                              key={key}
                              xs={3}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "0.562rem",
                                textAlign: "center",
                                fontWeight: 500,
                                p: "20px 8px",
                                border: "1px solid #E8E8E8",
                                borderTopLeftRadius: isTopLeft ? "8px" : 0,
                                borderTopRightRadius: isTopRight ? "8px" : 0,
                                borderBottomLeftRadius: isBottomLeft
                                  ? "8px"
                                  : 0,
                                borderBottomRightRadius: isBottomRight
                                  ? "8px"
                                  : 0,
                                backgroundColor: "#fff",
                              }}
                            >
                              {value}
                            </Grid>
                          );
                        }
                      )
                    )}
                  </Grid>
                </div>
              </Box>
            </Grid>

            <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  p: 2,
                  height: "21rem",
                  display: "flex",
                  flexDirection: "column",
                  bgcolor: "white",
                  borderRadius: "12px",
                }}
              >
                <h2>Actionable Insights</h2>

                <Grid container>
                  <Grid
                    item
                    xs={2.5}
                    sx={{
                      padding: "10px 0px",
                      fontWeight: 500,
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // whiteSpace: "nowrap",
                    }}
                  >
                    KPI
                  </Grid>
                  <Grid
                    item
                    xs={9.5}
                    sx={{
                      padding: "10px 0px",
                      fontWeight: 500,
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // whiteSpace: "nowrap",
                    }}
                  >
                    Actionable Insights
                  </Grid>
                </Grid>

                <Grid sx={{ flexGrow: 1, overflowY: "auto" }} container>
                  {formattedActionableData?.map((item, itemIndex) =>
                    Object.entries(item).map(
                      ([key, value], entryIndex, entries) => {
                        if (key === "code") return null;
                        // Determine if the item is in one of the corners
                        const isTopLeft = itemIndex === 0 && entryIndex === 0;
                        const isTopRight =
                          itemIndex === 0 && entryIndex === entries.length - 1;
                        const isBottomLeft =
                          itemIndex === formattedActionableData?.length - 1 &&
                          entryIndex === 0;
                        const isBottomRight =
                          itemIndex === formattedActionableData?.length - 1 &&
                          entryIndex === entries.length - 1;

                        return (
                          <Grid
                            item
                            key={key}
                            xs={entryIndex === 0 ? 2.5 : 3.1666}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "10px",
                              textAlign: key === "name" ? "center" : "left",
                              fontWeight: key === "name" ? 500 : 400,
                              p: "4px",
                              border: "1px solid #E8E8E8",
                              borderTopLeftRadius: isTopLeft ? "8px" : 0,
                              borderTopRightRadius: isTopRight ? "8px" : 0,
                              borderBottomLeftRadius: isBottomLeft ? "8px" : 0,
                              borderBottomRightRadius: isBottomRight
                                ? "8px"
                                : 0,
                              lineHeight: "14px",
                              flexShrink: 0,
                              minHeight: "50%",
                            }}
                          >
                            {value}
                          </Grid>
                        );
                      }
                    )
                  )}
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <div className="grading-section">
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        padding: "1rem",
                        bgcolor: "white",
                        borderRadius: "12px",
                      }}
                    >
                      <div style={{ backgroundColor: "#fcfcfc" }}>
                        <Grid container>
                          <Grid
                            item
                            xs={2}
                            sx={{
                              fontSize: "8px",
                              textAlign: "center",
                              p: "20px 0",
                              fontWeight: 500,
                            }}
                          >
                            Grade
                          </Grid>
                          {gradeData.map((item, index) => {
                            const isFirstItem = index === 0; // Check if the current item is the first
                            const isLastItem = index === gradeData.length - 1;
                            const color =
                              item.grade[0] === "A"
                                ? "#81DE76"
                                : item.grade[0] === "B"
                                ? "#FFA351"
                                : "#ED4A3C";
                            return (
                              <Grid
                                key={item.grade}
                                item
                                xs={1.25}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: color,
                                  fontSize: "9px",
                                  textAlign: "center",
                                  p: "10px 0",
                                  fontWeight: 700,
                                  border: "1px solid #F2F2F2",
                                  backgroundColor: "#fff",
                                  borderTopLeftRadius: isFirstItem ? "12px" : 0,
                                  borderTopRightRadius: isLastItem ? "12px" : 0,
                                }}
                              >
                                {item.grade}
                              </Grid>
                            );
                          })}
                        </Grid>
                        <Grid container>
                          <Grid
                            item
                            xs={2}
                            sx={{
                              fontSize: "8px",
                              textAlign: "center",
                              p: "15px 0",
                              fontWeight: 500,
                            }}
                          >
                            Score Range (Sectional/Overall)
                          </Grid>
                          {gradeData.map((item, index) => {
                            const isFirstItem = index === 0;
                            const isLastItem = index === gradeData.length - 1;
                            return (
                              <Grid
                                key={item.grade}
                                item
                                xs={1.25}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "8px",
                                  textAlign: "center",
                                  p: "15px 0",
                                  fontWeight: 500,
                                  border: "1px solid #F2F2F2",
                                  backgroundColor: "#fff",
                                  borderBottomLeftRadius: isFirstItem
                                    ? "12px"
                                    : 0,
                                  borderBottomRightRadius: isLastItem
                                    ? "12px"
                                    : 0,
                                }}
                              >
                                {item.score}
                              </Grid>
                            );
                          })}
                        </Grid>
                      </div>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      sx={{
                        bgcolor: "white",
                        borderRadius: "12px",
                        padding: "0 1rem",
                      }}
                    >
                      <Grid container sx={{ height: "50px" }}>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            bgcolor: "#ED4A3C",
                            heigh: "100%",
                            borderRadius: "12px 0 0 12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "white",
                          }}
                        >
                          Laggard
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            bgcolor: "#FFA351",
                            heigh: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "white",
                          }}
                        >
                          Average
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            bgcolor: "#81DE76",
                            heigh: "100%",
                            borderRadius: "0 12px 12px 0",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "white",
                          }}
                        >
                          Leader
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            <Grid item xs={6}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                  p: 1.5,
                  display: "flex",
                  maxHeight: "11.5rem",
                  flexDirection: "column",
                }}
              >
                <Grid container>
                  {kpiTableHeadings.map((item) => (
                    <Grid
                      key={item}
                      item
                      xs={4}
                      sx={{
                        fontSize: "0.75rem",
                        fontWeight: 500,
                        textAlign: "center",
                        backgroundColor: "#fcfcfc",
                        padding: "0 0 0.5rem 0",
                      }}
                    >
                      {item}
                    </Grid>
                  ))}
                </Grid>
                <Box
                  sx={{
                    flexGrow: 1,
                    overflowY: "auto",
                    overflowX: "hidden",
                    scrollbarWidth: "thin",
                    border: "1px solid #f2f2f2",
                    borderRadius: "12px",
                  }}
                >
                  {kpiTableData.map((item) => {
                    return (
                      <Grid
                        key={`${item.code} - ${item.kpi}`}
                        sx={{
                          borderBottom: "1px solid #f2f2f2",
                          fontSize: "0.67rem",
                          fontWeight: 500,
                          height: "auto",
                        }}
                        container
                      >
                        <Grid
                          item
                          xs={4}
                          sx={{
                            padding: "1.5rem 0.5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {item.code}
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            borderLeft: "1px solid #f2f2f2",
                            borderRight: "1px solid #f2f2f2",
                            padding: "1.5rem 0.5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {item.kpi}
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            padding: "1.5rem 0.5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {item.unit}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}

      <Grid container spacing={2.5} sx={{ marginBottom: "20px" }}>
        <Grid item xs={9}>
          <Box
            sx={{
              height: "100%",
              maxHeight: "43rem",
              width: "100%",
              paddingLeft: 10,
              paddingRight: 10,
              // paddingBottom: 7,
              // paddingTop: 13,
              paddingTop: 5,
              paddingBottom: 5,
              borderRadius: "12px",
              backgroundColor: " white",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <h2 className="score-breakdown_heading">Score Break down</h2>
                <div className="score-breakdown_total_kpis">
                  Total number of KPIs reported:{" "}
                  {`${report?.completedStatus?.done}/${report?.completedStatus?.total}`}
                </div>
              </div>

              <div className="score-breakdown_score">
                {report?.score?.toFixed(2)}
              </div>
            </div>

            <PartitionChart data={formattedRadialChartData} />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            sx={{
              height: "130px",
              width: "100%",
              p: "20px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#fff",
              borderRadius: "12px",
              marginBottom: "20px",
            }}
          >
            <div>
              <h2>Overall Grade</h2>
              <h3
                style={{
                  fontWeight: 500,
                  fontSize: "20px",
                  color: gradeColors[report?.grade?.[0]],
                  marginTop: "24px",
                }}
              >
                {report?.grade}
              </h3>
            </div>
          </Box>

          <Grid
            item
            xs={12}
            sx={{
              marginBottom: "20px",
            }}
          >
            <Box
              sx={{
                bgcolor: "white",
                borderRadius: "12px",
              }}
            >
              <Grid container sx={{ height: "50px" }}>
                <Grid
                  item
                  xs={4}
                  sx={{
                    bgcolor: "#ED4A3C",
                    heigh: "100%",
                    borderRadius: "12px 0 0 12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 500,
                    fontSize: "12px",
                    color: "white",
                  }}
                >
                  Laggard
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    bgcolor: "#FFA351",
                    heigh: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 500,
                    fontSize: "12px",
                    color: "white",
                  }}
                >
                  Average
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    bgcolor: "#81DE76",
                    heigh: "100%",
                    borderRadius: "0 12px 12px 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 500,
                    fontSize: "12px",
                    color: "white",
                  }}
                >
                  Leader
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Box
            sx={{
              width: "100%",
              p: "20px",

              backgroundColor: "#fff",
              borderRadius: "12px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                sx={{
                  fontSize: "12px",
                  textAlign: "center",
                  p: "1.2rem 0",
                  fontWeight: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fcfcfc",
                }}
              >
                Grade
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  fontSize: "12px",
                  textAlign: "center",
                  p: "1rem 0",
                  fontWeight: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fcfcfc",
                }}
              >
                Score Range (Sectional/Overall)
              </Grid>
            </Grid>

            <Box sx={{ border: "1px solid #F2F2F2", borderRadius: "12px" }}>
              {gradeData?.map((item, index) => {
                const color =
                  item.grade[0] === "A"
                    ? "#81DE76"
                    : item.grade[0] === "B"
                    ? "#FFA351"
                    : "#ED4A3C";

                return (
                  <Grid key={index} container>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        borderRight: "1px solid #F2F2F2",
                        borderBottom:
                          index === gradeData?.length - 1
                            ? "none"
                            : "1px solid #F2F2F2",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: color,
                        fontSize: "11px",
                        textAlign: "center",
                        p: "1rem 0",
                        fontWeight: 600,
                      }}
                    >
                      {item?.grade}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        borderBottom:
                          index === gradeData?.length - 1
                            ? "none"
                            : "1px solid #F2F2F2",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // color: color,
                        fontSize: "11px",
                        textAlign: "center",
                        p: "1rem 0",
                        fontWeight: 500,
                      }}
                    >
                      {item?.score}
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2.5} sx={{ marginBottom: "20px" }}>
        <Grid item xs={3}>
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "12px",
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "21rem",
            }}
          >
            <Grid container>
              {kpiTableHeadings?.map((item) => (
                <Grid
                  key={item}
                  item
                  xs={4}
                  sx={{
                    fontSize: "0.7rem",

                    fontWeight: 500,
                    textAlign: "center",
                    backgroundColor: "#fcfcfc",
                    padding: "0 0 0.5rem 0",
                  }}
                >
                  {item}
                </Grid>
              ))}
            </Grid>
            <Box
              sx={{
                flexGrow: 1,
                overflowY: "auto",
                overflowX: "hidden",
                scrollbarWidth: "thin",
                border: "1px solid #f2f2f2",
                borderRadius: "12px",
                // maxHeight: "18.5rem",
              }}
            >
              {kpiTableData?.map((item) => {
                return (
                  <Grid
                    key={`${item.code} - ${item.kpi}`}
                    sx={{
                      borderBottom: "1px solid #f2f2f2",
                      fontSize: "0.67rem",
                      fontWeight: 400,
                      height: "auto",
                    }}
                    container
                  >
                    <Grid
                      item
                      xs={4}
                      sx={{
                        padding: "1.5rem 0.5rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {item.code}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        // borderLeft: "1px solid #f2f2f2",
                        // borderRight: "1px solid #f2f2f2",
                        // padding: "1.5rem 0.5rem",
                        // display: "flex",
                        // justifyContent: "center",
                        // alignItems: "center",
                        borderLeft: "1px solid #f2f2f2",
                        borderRight: "1px solid #f2f2f2",
                        padding: "1rem 0.75rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        wordBreak: "break-word",
                      }}
                    >
                      {item.kpi}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        // padding: "1.5rem 0.5rem",
                        // display: "flex",
                        // justifyContent: "center",
                        // alignItems: "center",
                        padding: "1rem 0.75rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        wordBreak: "break-word",
                      }}
                    >
                      {item.unit}
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={4.5} md={4.5}>
          <Box
            sx={{
              p: 2,
              height: "21rem",
              bgcolor: "white",
              borderRadius: "12px",
              overflow: "hidden", // Prevent outer overflow
            }}
          >
            <h2>Intensities Table</h2>

            <div
              style={{
                backgroundColor: "#fcfcfc",
                padding: "6px",
                borderRadius: "12px",
                height: "calc(100% - 40px)", // Subtract header height
                overflow: "auto", // Enable scrolling
              }}
            >
              <Grid container>
                {intensitiesTableHeading?.map((item) => (
                  <Grid
                    key={item}
                    item
                    xs={3}
                    sx={{
                      mt: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "0.562rem",
                      textAlign: "center",
                      fontWeight: 500,
                      p: "5px",
                    }}
                  >
                    {item}
                  </Grid>
                ))}
              </Grid>

              <Grid container sx={{ borderCollapse: "collapse" }}>
                {intensitiesTableData?.map((item, itemIndex) =>
                  Object.entries(item)?.map(
                    ([key, value], entryIndex, entries) => {
                      const isTopLeft = itemIndex === 0 && entryIndex === 0;
                      const isTopRight =
                        itemIndex === 0 && entryIndex === entries.length - 1;
                      const isBottomLeft =
                        itemIndex === intensitiesTableData.length - 1 &&
                        entryIndex === 0;
                      const isBottomRight =
                        itemIndex === intensitiesTableData.length - 1 &&
                        entryIndex === entries.length - 1;

                      return (
                        <Grid
                          item
                          key={key}
                          xs={3}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "0.562rem",
                            textAlign: "center",
                            fontWeight: 500,
                            p: "20px 8px",
                            border: "1px solid #E8E8E8",
                            borderTopLeftRadius: isTopLeft ? "8px" : 0,
                            borderTopRightRadius: isTopRight ? "8px" : 0,
                            borderBottomLeftRadius: isBottomLeft ? "8px" : 0,
                            borderBottomRightRadius: isBottomRight ? "8px" : 0,
                            backgroundColor: "#fff",
                          }}
                        >
                          {value}
                        </Grid>
                      );
                    }
                  )
                )}
              </Grid>
            </div>
          </Box>
        </Grid>

        <Grid item xs={4.5}>
          <Box
            sx={{
              p: 2,
              height: "21rem",
              display: "flex",
              flexDirection: "column",
              bgcolor: "white",
              borderRadius: "12px",
              marginTop: "5px",
            }}
          >
            <h2>Actionable Insights</h2>

            <Grid container>
              <Grid
                item
                xs={2.5}
                sx={{
                  padding: "10px 0px",
                  fontWeight: 500,
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // whiteSpace: "nowrap",
                }}
              >
                KPI
              </Grid>
              <Grid
                item
                xs={9.5}
                sx={{
                  padding: "10px 0px",
                  fontWeight: 500,
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // whiteSpace: "nowrap",
                }}
              >
                Actionable Insights
              </Grid>
            </Grid>

            <Grid
              sx={{
                flexGrow: 1,
                overflowY: "auto",
                // border: "1px solid black",
                // borderRadius: "12px",
              }}
              container
            >
              {formattedActionableData?.map((item, itemIndex) =>
                Object.entries(item)?.map(
                  ([key, value], entryIndex, entries) => {
                    if (key === "code") return null;
                    // Determine if the item is in one of the corners
                    const isTopLeft = itemIndex === 0 && entryIndex === 0;
                    const isTopRight =
                      itemIndex === 0 && entryIndex === entries.length - 1;
                    const isBottomLeft =
                      itemIndex === formattedActionableData?.length - 1 &&
                      entryIndex === 0;
                    const isBottomRight =
                      itemIndex === formattedActionableData?.length - 1 &&
                      entryIndex === entries.length - 1;

                    return (
                      <Grid
                        item
                        key={key}
                        xs={entryIndex === 0 ? 2.5 : 3.1666}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "10px",
                          textAlign: key === "name" ? "center" : "left",
                          fontWeight: key === "name" ? 500 : 400,
                          p: "4px",
                          border: "1px solid #E8E8E8",
                          borderTopLeftRadius: isTopLeft ? "8px" : 0,
                          borderTopRightRadius: isTopRight ? "8px" : 0,
                          borderBottomLeftRadius: isBottomLeft ? "8px" : 0,
                          borderBottomRightRadius: isBottomRight ? "8px" : 0,
                          lineHeight: "14px",
                          flexShrink: 0,
                          minHeight: "50%",
                        }}
                      >
                        {value}
                      </Grid>
                    );
                  }
                )
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BaseSectorZeroState;
