import React, { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Slider,
  Typography,
} from "@mui/material";

const ImageModal = ({
  open,
  setOpen,
  imageUrl,
  setImageApi,
  setCroppedImage,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  // const getCroppedImg = async (imageSrc, pixelCrop) => {
  //   const image = await createImage(imageSrc);
  //   console.log(image);
  //   const canvas = document.createElement("canvas");
  //   const ctx = canvas.getContext("2d");

  //   if (!ctx) {
  //     return null;
  //   }

  //   const diameter = Math.min(pixelCrop.width, pixelCrop.height);
  //   canvas.width = diameter;
  //   canvas.height = diameter;

  //   ctx.beginPath();
  //   ctx.arc(diameter / 2, diameter / 2, diameter / 2, 0, 2 * Math.PI);
  //   ctx.clip();

  //   ctx.drawImage(
  //     image,
  //     pixelCrop.x,
  //     pixelCrop.y,
  //     diameter,
  //     diameter,
  //     0,
  //     0,
  //     diameter,
  //     diameter
  //   );

  //   return canvas.toDataURL("image/jpeg", 0.9);
  // };

  const getCroppedImg = async (imageSrc, pixelCrop, quality = 0.7) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    // Set canvas size to be the size of the crop area
    const diameter = Math.min(pixelCrop.width, pixelCrop.height);
    canvas.width = diameter;
    canvas.height = diameter;

    // Draw the circular cropped image
    ctx.beginPath();
    ctx.arc(diameter / 2, diameter / 2, diameter / 2, 0, 2 * Math.PI);
    ctx.clip();

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      diameter,
      diameter,
      0,
      0,
      diameter,
      diameter
    );

    // Convert the canvas to a data URL in JPEG format with the specified quality
    return canvas.toDataURL("image/jpeg", quality);
  };

  const dataURLtoFile = (dataurl, filename) => {
    console.log(dataurl);
    let arr = dataurl.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const reset = () => {
    setZoom(1);
    setCroppedAreaPixels(null);
    setOpen(false);
    setCrop({ x: 0, y: 0 });
  };

  // const showCroppedImage = useCallback(async () => {
  //   try {
  //     if (!croppedAreaPixels) {
  //       throw new Error("No cropped area pixels");
  //     }
  //     const croppedImageDataURL = await getCroppedImg(
  //       imageUrl,
  //       croppedAreaPixels
  //     );

  //     if (croppedImageDataURL) {
  //       console.log(croppedImageDataURL);
  //       const croppedImageFile = dataURLtoFile(
  //         croppedImageDataURL,
  //         "cropped_image.jpg"
  //       );

  //       if (croppedImageFile) {
  //         const url = URL.createObjectURL(croppedImageFile);
  //         setImageApi(url);
  //         setCroppedImage(croppedImageFile);
  //         reset();
  //       }
  //     }
  //   } catch (e) {
  //     reset();
  //     console.error("Error creating cropped image:", e);
  //   }
  // }, [imageUrl, croppedAreaPixels, setImageApi]);

  const showCroppedImage = useCallback(async () => {
    try {
      if (!croppedAreaPixels) {
        throw new Error("No cropped area pixels");
      }

      // Reduce quality to 0.7 (or any other value, lower values result in smaller sizes)
      const croppedImageDataURL = await getCroppedImg(
        imageUrl,
        croppedAreaPixels,
        0.7 // Adjust the quality here
      );

      console.log(croppedImageDataURL);

      if (croppedImageDataURL) {
        // Convert data URL to File object
        const croppedImageFile = dataURLtoFile(
          croppedImageDataURL,
          "cropped-image.jpg"
        );

        console.log(croppedImageFile);

        if (croppedImageFile) {
          const url = URL.createObjectURL(croppedImageFile);
          setImageApi(url);
          setCroppedImage(croppedImageFile);
          // const formData = new FormData();
          // formData.append("image", croppedImageFile);

          reset();
        }
      }
    } catch (e) {
      reset();
      console.error("Error creating cropped image:", e);
    }
  }, [imageUrl, croppedAreaPixels, setImageApi]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        reset();
        setOpen(false);
      }}
      sx={{
        ".MuiBackdrop-root": {
          background: "rgba(0, 0, 0, 0.3)",
        },
        ".MuiPaper-root": {
          width: "100%",
          maxWidth: 520,
          height: "80vh",
          borderRadius: "1rem",
          padding: "16px",
        },
      }}
    >
      <DialogContent
        style={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* Heading */}
        <Typography
          variant="h5"
          sx={{
            marginBottom: "16px",
          }}
        >
          Edit Image
        </Typography>

        {/* Cropper */}
        <Box
          sx={{
            backgroundColor: "#000",
            position: "relative",
            width: "100%",
            height: "400px",
            marginBottom: "16px",
            "@media (max-width: 600px)": {
              height: "300px",
            },
          }}
        >
          <Cropper
            style={{
              backgroundColor: "#000",
            }}
            image={imageUrl}
            crop={crop}
            zoom={zoom}
            aspect={1}
            cropShape="round"
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Box>

        {/* Slider */}
        <Box sx={{ marginBottom: "16px" }}>
          <Slider
            min={1}
            max={10}
            value={zoom}
            onChange={(e) => setZoom(Number(e.target.value))}
            aria-label="Zoom"
            valueLabelDisplay="auto"
            color={"#B7B7B7"}
            sx={{
              width: "100%",
              "& .MuiSlider-thumb": {
                height: 24,
                width: 24,
                backgroundColor: "#B7B7B7",
                boxShadow: "none",
              },
            }}
          />
        </Box>

        {/* Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "0.8rem",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              reset();
              setOpen(false);
            }}
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              padding: ".5rem 1.5rem",
              textTransform: "none",
              borderRadius: "8px",
              color: "#369D9C",
              borderColor: "#369D9C",
              ":hover": {
                borderColor: "#369D9C",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={showCroppedImage}
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              padding: ".5rem 1.5rem",
              textTransform: "none",
              borderRadius: "8px",
              color: "#FFF",
              backgroundColor: "#369D9C",
              ":hover": {
                backgroundColor: "#369D9C",
              },
            }}
          >
            Upload
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ImageModal;
