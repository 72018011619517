import api from ".";

export const getAllChatMessages = async () => {
  try {
    const response = await api.get("chat/all-messages");
    // console.log("response" , response)
    // console.log("response data",response.data)
    return response.data;
    
  } catch (error) {
    console.error("Error fetching chat messages:", error);
    throw error;
  }
};
