// import { io } from "socket.io-client";
// // import { backendUrl as baseURL } from "../../config";
// import constant from "../../constant";

// const baseURL = constant.BACKEDN_BASE_URL;

// let socket = null;

// export const initializeSocket = (callback) => {
//   console.log("initialized");

//   const token = localStorage.getItem("token");
//   console.log(baseURL, token, "url,token");

//   socket = io(baseURL, {
//     auth: {
//       token: token, // Include the token here
//     },
//   });

//   socket.on("message", (message) => {
//     console.log("socket message", message);
//     callback(message);
//   });

//   socket.on("newMessage", (message) => {
//     console.log("socket message", message);
//     callback(message);
//   });
// };

// // export const newMessage = (callback) => {
// //   console.log("initalize socket new message");
// //   if (!socket) {
// //     initializeSocket();
// //   }

// //   socket.on("newMessage", (message) => {
// //     console.log("emit message 2");
// //     callback(message);
// //   });
// // };

import io from "socket.io-client";
// import constant from "../../constant";

let socket = null;

export const initializeSocket = (callback) => {
  console.log("initialized");
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("token");

    socket = io(process.env.REACT_APP_SOCKET_URL, {
      auth: {
        token: token,
      },
    });

    // Default connection events
    socket.on("userConnected", (response) => {
      console.log("sock", response);
      // eventHandlers(response);
      // resolve(response);
    });
    socket.on("message", (message) => {
      console.log("socket message", message);
      callback(message);
    });

    socket.on("newMessage", (message) => {
      console.log("socket message", message);
      callback(message);
    });

    // socket.on("workspaceUpdate", (response) => {
    //   eventHandlers(response);
    // });

    // socket.on("workspaceSchemaUpdate", (response) => {
    //   eventHandlers(response);
    // });

    // socket.on("invitation", (response) => {
    //   eventHandlers(response);
    // });

    socket.on("connect_error", (error) => {
      console.log("err", error);
      // eventHandlers(error);
    });
  });
};

export const getSocket = () => socket;

// Helper function to disconnect socket
export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};
