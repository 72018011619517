import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import gender from "../../../assets/sustainabilityReport/gender.svg";
import trip from "../../../assets/sustainabilityReport/TRIP.svg";
import indirectEmp from "../../../assets/sustainabilityReport/indirectEmp.svg";
import foreignEmp from "../../../assets/sustainabilityReport/foreignEmp.svg";
import PieChart from "../PieChart";
import DoughnutChart from "../DoughnutChart";
import { convertDecimalToYearsAndMonths } from "../../../utils/decimalToYears";
import clock from "../../../assets/sustainabilityReport/clock.svg";

const SocialStatsCard = ({ icon, title, amount }) => {
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "#FAE6EA",
        p: "20px",
        borderRadius: "12px",
        height: "100%",
      }}
    >
      <img
        src={icon}
        alt={title}
        height={24}
        width={24}
        style={{ marginBottom: "6px" }}
      />
      <h4 className="base-sector-card_title">{title}</h4>
      <Typography variant="h5" sx={{ fontSize: "18px" }}>
        {amount?.toLocaleString("en-IN")}
      </Typography>
    </Box>
  );
};

const SocialZeroState = ({ report }) => {
  const { socialKPI } = report;

  const totalPopulation =
    socialKPI?.S1?.totalMen?.value +
    socialKPI?.S1?.totalWomen?.value +
    socialKPI?.S1?.totalNonBinary?.value;

  const cardData = [
    {
      id: 0,
      icon: gender,
      title: "Gender Ratio in Workforce",
      amount: (socialKPI?.S1?.totalMen?.value / totalPopulation)?.toFixed(2),
    },
    {
      id: 1,
      icon: trip,
      title: "TRIR (Incidents per 200,000 person hours)",
      amount: socialKPI?.S7?.trir?.value,
    },
    {
      id: 2,
      icon: indirectEmp,
      title: "Proportion of Indirect employees (%)",
      amount: socialKPI?.S3?.indirectEmployees?.value,
    },
    {
      id: 3,
      icon: foreignEmp,
      title: "Proportion of foreign employees (%)",
      amount: socialKPI?.S2?.foreignEmployees?.value,
    },
    {
      id: 4,
      icon: clock,
      title: "Hours of training provided to employees",
      amount: socialKPI?.S5?.trainingHours?.value
    },
  ];

  const genderPieChart = [
    { label: "Men", value: socialKPI?.S1?.totalMen?.value },
    { label: "Women", value: socialKPI?.S1?.totalWomen?.value },
    { label: "Non-Binary", value: socialKPI?.S1?.totalNonBinary?.value },
  ];

  const avgEmployeeTenureDoughnut = [
    {
      label: "Average Employee Tenure",
      value: socialKPI?.S4?.averageTenure?.value,
    },
    {
      label: "Remaining out of 10",
      value: 10 - socialKPI?.S4?.averageTenure?.value,
    },
  ];

  const avgEmpTenure = convertDecimalToYearsAndMonths(
    socialKPI?.S4?.averageTenure?.value
  );

  return (
    <>
      <h1 className="sustainability-section_heading">Social Index</h1>

      {/* PINK DIVS */}
      <Grid container spacing={2.5} sx={{ mb: "20px" }}>
        {cardData.map((item) => (
          <Grid key={item.id} item xs={2.4}>
            <SocialStatsCard {...item} />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2.5} sx={{ mb: "20px" }}>
        <Grid item xs={6}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "12px",
              p: 2,
              backgroundColor: "#FFF",
              height: "21rem",
            }}
          >
            <h2>Gender Diversity</h2>
            <div style={{ height: "220px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <PieChart
                    colors={["#FAE6EA", "#FF8FA5", "#FFF5F7"]}
                    data={genderPieChart}
                    width={width}
                    height={height}
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "12px",
              p: 2,
              backgroundColor: "#FFF",
            }}
          >
            <h2>Average Employee Tenure</h2>
            <div style={{ height: "220px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <DoughnutChart
                    colors={["#FF8FA5", "#FAE6EA"]}
                    data={avgEmployeeTenureDoughnut}
                    width={width}
                    height={height}
                    centerText={`${avgEmpTenure?.years}`}
                    subText={`Years & ${avgEmpTenure.months} months`}
                    unit="years"
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>

        {/* <Grid item xs={4.5}>
          <Box
            sx={{
              p: 2,
              backgroundColor: "#FAE6EA",
              display: "flex",
              alignItems: "center",
              height: "100%",
              borderRadius: "12px",
            }}
          >
            <div>
              <img src={clock} height={30} width={30} alt="Clock" />
              <p
                style={{ fontSize: "1rem", fontWeight: 500, marginTop: "10px" }}
              >
                Hours of training provided to employees
              </p>
              <p
                style={{ fontSize: "22px", fontWeight: 600, marginTop: "10px" }}
              >
                {socialKPI?.S5?.trainingHours?.value}
              </p>
            </div>
          </Box>
        </Grid> */}
      </Grid>
    </>
  );
};

export default SocialZeroState;
